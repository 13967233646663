import styled, { css } from 'styled-components';
import LeafletMap from '../LeafletMap/LeafletMap';
import { Information } from './components/Information/Information';
import { Employees } from './components/Employees/Employees';
import { useSelector } from 'react-redux';
import { IState } from '../../store';
import { AdCard } from '../AdCard/AdCard';
import { BannerItem, CompanyResponse } from '../../types/api';
import { Charts } from './components/Charts/Charts';

const InfoContainer = styled.div`
    display: flex;
    justify-content: center;
`;
const InfoWrap = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: auto;
`;
const Hero = styled.div`
    ${(props) => css`
        width: 75%;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;
const SideBar = styled.div`
    ${(props) => css`
        width: 23%;
        ${!props.theme.screen.desktop &&
        css`
            display: flex;
            justify-content: center;
            width: 100%;
        `}
    `}
`;
const AdsWrapper = styled.div`
    ${(props) => css`
        margin-top: 30px;
        display: grid;
        grid-template-columns: 100%;
        max-width: 250px;
        gap: 16px;
        padding: 16px;
        border: 1px solid ${props.theme.colors.outlineGrey};
        ${!props.theme.screen.desktop &&
        css`
            border: none;
            padding: 0;
            max-width: 282px;
        `}
    `}
`;
const Ad = styled.div`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineGrey};
        padding: 16px;
    `}
`;

export const InformationAndEmployees = (props: CompanyResponse) => {
    const { screenViewPort, bannersList } = useSelector((state: IState) => state.global);

    const isFinancialDataPresent =
        props.data.workerStatistics.totalCount?.length > 0 || props.data.financialStats.sodraDebt?.length > 0;

    return (
        <InfoContainer>
            <InfoWrap>
                <Hero>
                    <Information data={props.data} />
                    <Employees />
                    {isFinancialDataPresent && (
                        <Charts
                            financialStats={props.data.financialStats}
                            employeeStatistics={props.data.workerStatistics}
                        />
                    )}
                </Hero>
                <SideBar>
                    {screenViewPort === 'desktop' && (
                        <LeafletMap coordinates={props.data.address} leafletHeight={'height'} />
                    )}
                    <AdsWrapper>
                        {bannersList.WBM.map((banner: BannerItem, index) => {
                            if (screenViewPort !== 'desktop' && index < 3) {
                                return (
                                    <Ad key={index}>
                                        <AdCard adUrl={banner.image} adHref={banner.url} />
                                    </Ad>
                                );
                            }
                            if (screenViewPort === 'desktop') {
                                return <AdCard adUrl={banner.image} key={index} adHref={banner.url} />;
                            }
                            return null;
                        })}
                    </AdsWrapper>
                </SideBar>
            </InfoWrap>
        </InfoContainer>
    );
};
