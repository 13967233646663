import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ICategory } from '../../store/categories/types';
import { IconById } from './IconById';

const Card = styled.div`
    ${(props) => css`
        text-align: center;
        background-color: ${props.theme.colors.greyBg};
        border-radius: 12px;
        display: flex;
        height: 188px;
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        color: ${props.theme.colors.black};
        ${!props.theme.screen.desktop &&
        css`
            height: 146px;
        `}
        &:hover {
            transition: box-shadow 0.2s ease-in-out;
            box-shadow: 0px 3px 0px ${props.theme.colors.darkGrey};
            cursor: pointer;
            .category-name {
                font-weight: 700;
            }
        }
    `}
`;
const TextContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Text = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 2px;
    text-transform: capitalize;
`;
const Icon = styled.div`
    padding-top: 32px;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            padding-top: 19px;
        `}
`;

interface Props {
    category: ICategory;
}

export const CategoryCard = (props: Props) => {
    const navigate = useNavigate();
    const namesSplit = props.category.name.split(',');

    const navigateToCategoryTopics = () => {
        const state = { categoryId: props.category.id };
        navigate('/visos-kategorijos', { state });
    };

    return (
        <Card onClick={navigateToCategoryTopics}>
            <Icon>
                <IconById id={props.category.id} customStyles={{ width: 44, height: 44 }} />
            </Icon>
            <TextContainer>
                {namesSplit.map((name, i) => (
                    <Text key={i} className="category-name">
                        {name}
                    </Text>
                ))}
            </TextContainer>
        </Card>
    );
};
