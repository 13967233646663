export const theme = {
    colors: {
        black: '#170D17',
        lighterBlack: '#212121',
        darkGrey: '#666666',
        outlineAndPlaceholder: '#7F7F7F',
        outlineGrey: '#E4E4E4',
        greyBg: '#F4F4F4',
        white: '#FFFFFF',
        shadow: 'rgba(0, 0, 0, 0.1)',
        yellow: '#FFBC22',
        darkerYellow: '#FFAD22',
        darkYellow: '#E69100',
        error: '#FF5522',
        success: '#8AC362',
    },
    screen: {
        desktop: false,
        tablet: false,
        mobile: false,
    },
};
