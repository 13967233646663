import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { IState } from '../../store';
import { Logos } from './Logos';

import arrowSide from '@mui/icons-material/ExpandCircleDown';

const SliderContainer = styled.div`
    display: flex;
    justify-content: center;
`;
const SliderWrap = styled.div`
    max-width: 1254px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;
const SliderCardsContainer = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 0 30px;
        &.info-gallery {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            ${!props.theme.screen.desktop &&
            css`
                grid-template-columns: repeat(2, 1fr);
            `}
        }
        ${props.theme.screen.mobile &&
        css`
            gap: 0 23px;
        `}
    `}
`;
const SliderNavigationContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
`;
const ArrowSide = styled(arrowSide)`
    ${(props) => css`
        width: 20px;
        height: 20px;
        margin: 0 16px;
        cursor: pointer;
        rotate: 270deg;
        fill: ${props.theme.colors.black};
        ${props.theme.screen.mobile &&
        css`
            width: 29px;
            height: 29px;
        `}
        &.left-arrow {
            rotate: 90deg;
        }
        &.disabled {
            pointer-events: none;
            filter: opacity(0.4);
        }
    `}
`;
const Dot = styled.a`
    height: 6px;
    width: 6px;
    cursor: pointer;
    text-decoration: none;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.black};
`;
const DotWrap = styled.div`
    height: 12px;
    width: 12px;
    margin: 0 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
        border: 1px solid ${(props) => props.theme.colors.black};
        border-radius: 50px;
    }
`;

interface WeeklyCompanyLogo {
    logoPath: string;
    url: string;
}

interface Props {
    amountPerScreen: {
        desktop: number;
        tablet: number;
        mobile: number;
    };
    gallerySize?: boolean;
    logoUrls: string[] | WeeklyCompanyLogo[];
    weeklyCompanies?: boolean;
    isInfoGallery?: boolean;
}
interface State {
    imagesPerPage: number;
    currentPage: number;
    shownImages: string[] | WeeklyCompanyLogo[];
    pageCount: number;
}

export const Slider = (props: Props) => {
    const imagesPerPageDesktop = props.amountPerScreen.desktop;
    const imagesPerPageTablet = props.amountPerScreen.tablet;
    const imagesPerPageMobile = props.amountPerScreen.mobile;
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const [state, setState] = useState<State>({
        currentPage: 1,
        imagesPerPage: imagesPerPageDesktop,
        shownImages: [],
        pageCount: Math.ceil(props.logoUrls.length / imagesPerPageDesktop),
    });

    const calculateImagesPerPage = (viewPort: string) => {
        if (viewPort === 'desktop') {
            // Desktop
            setImageView(imagesPerPageDesktop);
        }
        if (viewPort === 'tablet') {
            // Tablet
            setImageView(imagesPerPageTablet);
        }
        if (viewPort === 'mobile') {
            // Mobile
            setImageView(imagesPerPageMobile);
        }
    };

    const setImageView = (imagesPerPage: number) => {
        setState((prev) => {
            const index = findImageIndex(prev.shownImages[0]);
            const pageCount = Math.ceil(props.logoUrls.length / imagesPerPage);
            const calculatedPage =
                Math.floor(index / imagesPerPage) + 1 > pageCount ? pageCount : Math.floor(index / imagesPerPage) + 1;
            const currentPage = index === 0 ? 1 : calculatedPage;

            return {
                ...prev,
                imagesPerPage: imagesPerPage,
                pageCount,
                currentPage,
            };
        });
    };

    const findImageIndex = (imageUrl: string | WeeklyCompanyLogo) => {
        if (!imageUrl) {
            return 0;
        }
        return props.logoUrls.findIndex((image) => image === imageUrl);
    };

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            shownImages: props.logoUrls.slice(
                (state.currentPage - 1) * state.imagesPerPage,
                state.currentPage * state.imagesPerPage
            ),
        }));
    }, [state.currentPage, state.imagesPerPage, props.logoUrls]);

    useEffect(() => {
        calculateImagesPerPage(screenViewPort);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenViewPort]);

    const goToPrevPage = () => {
        setState((prev) => ({
            ...prev,
            currentPage: prev.currentPage - 1,
        }));
    };

    const goToNextPage = () => {
        setState((prev) => ({
            ...prev,
            currentPage: prev.currentPage + 1,
        }));
    };

    const goToSpecificPage = (page: number) => {
        setState((prev) => ({
            ...prev,
            currentPage: page,
        }));
    };

    return (
        <SliderContainer>
            <SliderWrap>
                <SliderCardsContainer className={classNames({ 'info-gallery': props.isInfoGallery })}>
                    {state.shownImages.map((image, index) => (
                        <Logos
                            img={image}
                            key={index}
                            adjustSize={props.gallerySize}
                            weeklyCompanies={props.weeklyCompanies}
                            isInfoGallery={props.isInfoGallery}
                        />
                    ))}
                </SliderCardsContainer>
                <SliderNavigationContainer>
                    <ArrowSide
                        className={classNames('left-arrow', { disabled: state.currentPage === 1 })}
                        onClick={goToPrevPage}
                    />
                    {Array.from(Array(state.pageCount), (e, i) => (
                        <DotWrap
                            key={i}
                            className={classNames({ active: state.currentPage === i + 1 })}
                            onClick={() => goToSpecificPage(i + 1)}
                        >
                            <Dot />
                        </DotWrap>
                    ))}
                    <ArrowSide
                        className={classNames('right-arrow', { disabled: state.currentPage === state.pageCount })}
                        onClick={goToNextPage}
                    />
                </SliderNavigationContainer>
            </SliderWrap>
        </SliderContainer>
    );
};
