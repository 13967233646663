import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CategoryTopics } from '../../components/CategoryTopics/CategoryTopics';
import { AdCard } from '../../components/AdCard/AdCard';

import { getCategoriesList } from '../../services/categories.service';
import { setCategoriesList } from '../../store/categories/categories.slice';
import { IState } from '../../store';

import styled, { css } from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import { ICategory } from '../../store/categories/types';
import { useLocation } from 'react-router-dom';
import { AutocompleteInput } from '../../components/SearchBar/AutocompleteInput';

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    max-width: 1254px;
    margin: 0 16px 16px;
`;

const CategoryTopicsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    gap: 39px;
    justify-content: center;
`;

const AdsWrapper = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: 100%;
        max-width: 282px;
        padding: 16px;
        gap: 16px;
        border: 1px solid ${props.theme.colors.outlineGrey};
        height: 100%;
        ${!props.theme.screen.desktop &&
        css`
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
        `}
    `}
`;

const ExpandedRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Heading = styled.div`
    font-size: 32px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.black};
`;

const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 20px;
    ${(props) =>
        props.theme.screen.mobile &&
        css`
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
        `}
`;

const InputWrapper = styled.form`
    display: flex;
    width: 100%;
    max-width: 359px;
    border-radius: 8px 0 0 8px;
`;
const SearchIconWrapper = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${props.theme.colors.black};
        width: 48px;
        height: 48px;
        border-radius: 0px 8px 8px 0px;
        .icon {
            color: ${props.theme.colors.white};
        }
    `}
`;

interface ShowMoreState {
    [key: number]: boolean;
}

export const CategoriesAndTopics = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { state } = useLocation();

    const { categoriesList } = useSelector((state: IState) => state.categories);
    const { searchFilters } = useSelector((state: IState) => state.companies);
    const { screenViewPort, bannersList } = useSelector((state: IState) => state.global);

    const [showMore, setShowMore] = useState<ShowMoreState>({});
    const [searchValue, setSearchValue] = useState<string>('');
    const [filteredCategoriesList, setFilteredCategoriesList] = useState<ICategory[]>([]);

    useEffect(() => {
        if (categoriesList.length === 0) {
            getCategoriesList().then((resp) => {
                dispatch(setCategoriesList(resp));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (state?.categoryId) {
            const categories = categoriesList.filter((category) => category.id === state.categoryId);
            setFilteredCategoriesList(categories);
        } else {
            setFilteredCategoriesList(categoriesList);
        }
        categoriesList.forEach((category) => {
            setShowMore((prevState) => ({ ...prevState, [category.id]: false }));
        });
    }, [categoriesList, state?.categoryId]);

    const filterCategoriesList = () => {
        if (searchValue.length >= 3) {
            const filteredCategories: ICategory[] = [];

            categoriesList.forEach((category) => {
                const filteredTopics = category.topics.filter((topic) =>
                    topic.name.toLowerCase().includes(searchValue.toLowerCase())
                );
                if (filteredTopics.length > 0) {
                    filteredCategories.push({ ...category, topics: filteredTopics });
                }
            });
            setFilteredCategoriesList(filteredCategories);
            return;
        }
        setFilteredCategoriesList(categoriesList);
    };

    const setShowMoreValue = (value: boolean, categoryId: number) => {
        setShowMore((prevState) => ({ ...prevState, [categoryId]: value }));
    };

    return (
        <Content>
            <MainWrapper>
                <HeaderWrapper>
                    <Heading>{t('all_categories')}</Heading>
                    <InputWrapper
                        onSubmit={(e) => {
                            e.preventDefault();
                            filterCategoriesList();
                        }}
                    >
                        <AutocompleteInput
                            class={'category-search'}
                            onChange={(value) => setSearchValue(value)}
                            name={t('category_search_placeholder')}
                            options={searchFilters.topics.map((topic) => topic.name)}
                            id="topic_search"
                        />
                        <SearchIconWrapper onClick={() => filterCategoriesList()}>
                            <SearchIcon className="icon" />
                        </SearchIconWrapper>
                    </InputWrapper>
                </HeaderWrapper>
                <Wrapper>
                    <CategoryTopicsWrapper>
                        {filteredCategoriesList.length > 0 &&
                            filteredCategoriesList.map((category, index) => {
                                return (
                                    <React.Fragment key={category.id}>
                                        <CategoryTopics
                                            key={category.id}
                                            category={category}
                                            showMore={showMore[category.id]}
                                            setShowMoreValue={setShowMoreValue}
                                        />
                                        {Number.isInteger((index + 1) / 3) &&
                                            bannersList.WBM[(index + 1) / 3 - 1] &&
                                            screenViewPort !== 'desktop' && (
                                                <ExpandedRow>
                                                    <AdsWrapper>
                                                        <AdCard
                                                            adUrl={bannersList.WBM[(index + 1) / 3 - 1].image}
                                                            adHref={bannersList.WBM[(index + 1) / 3 - 1].url}
                                                        />
                                                    </AdsWrapper>
                                                </ExpandedRow>
                                            )}
                                    </React.Fragment>
                                );
                            })}
                    </CategoryTopicsWrapper>
                    {screenViewPort === 'desktop' && (
                        <AdsWrapper>
                            {bannersList.WBM.length > 0 &&
                                bannersList.WBM.slice(0, 5).map((banner, index) => {
                                    return <AdCard key={index} adUrl={banner.image} adHref={banner.url} />;
                                })}
                        </AdsWrapper>
                    )}
                </Wrapper>
            </MainWrapper>
        </Content>
    );
};
