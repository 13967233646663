import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../assets/images/Footer/footer_logo_desktop.svg';
import { ReactComponent as FBLogo } from '../../assets/images/Footer/facebook.svg';
import InstagramIcon from '@mui/icons-material/Instagram';
import { ReactComponent as Lext } from '../../assets/images/Footer/lext.svg';
import { ReactComponent as InfoMedia } from '../../assets/images/Footer/infomedia_logo.svg';
import { ROUTE_NAMES } from '../../constants/routes';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IState } from '../../store';

const FooterWrap = styled.div`
    ${(props) => css`
        z-index: 3;
        position: relative;
        display: flex;
        justify-content: center;
        background-color: ${props.theme.colors.black};
        ${props.theme.screen.mobile &&
        css`
            height: fit-content;
        `}
    `}
`;
const Wrap = styled.div`
    ${(props) => css`
        display: flex;
        flex-direction: column;
        ${props.theme.screen.mobile &&
        css`
            width: calc(50% - 16px);
        `}
    `}
`;
const FooterContainer = styled.div`
    ${(props) => css`
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 48px 16px;
        width: 1254px;
        color: ${props.theme.colors.white};
        ${props.theme.screen.mobile &&
        css`
            padding: 24px 16px;
        `}
    `}
`;
const LeftSide = styled.div`
    ${(props) => css`
        display: flex;
        gap: 30px;
        justify-content: space-between;
        align-items: flex-start;
        width: 43%;
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
            flex-wrap: wrap;
        `}
    `}
`;
const Linking = styled(Link)`
    ${(props) => css`
        text-decoration: none;
        color: ${props.theme.colors.white};
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.005em;
        &.home-link {
            ${props.theme.screen.mobile &&
            css`
                width: 100%;
            `}
        }
    `}
`;
const Button = styled.a`
    ${(props) => css`
        text-decoration: none;
        color: ${props.theme.colors.white};
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0.005em;
        margin-bottom: 16px;
    `}
`;
const Icons = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 8px;
`;
const Facebook = styled(FBLogo)`
    margin-right: 24px;
`;
const Instagram = styled(InstagramIcon)`
    margin-top: 2px;
`;
const RightSide = styled.div`
    ${(props) => css`
        width: 50%;
        max-width: 393px;
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
            max-width: unset;
            margin-top: 32px;
        `}
    `}
`;
const Logos = styled.div`
    display: flex;
`;
const LextLogo = styled(Lext)`
    ${(props) => css`
        ${props.theme.screen.mobile &&
        css`
            max-width: 40px;
        `}
    `}
`;
const InfoMediaLogo = styled(InfoMedia)`
    margin-top: 2px;
    min-width: 102px;
    ${(props) => css`
        ${props.theme.screen.mobile &&
        css`
            min-width: 85px;
        `}
    `}
`;
const Divider = styled.div`
    height: auto;
    width: 1px;
    background-color: ${(props) => props.theme.colors.outlineGrey};
    margin: 0 16px;
`;
const HDivider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.outlineGrey};
    margin-bottom: 16px;
`;
const Text = styled.div`
    ${(props) => css`
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        margin-left: 16px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 10px;
            line-height: 12px;
        `}
    `}
`;
const LogoText = styled.div`
    display: flex;
`;
const Anchor = styled.a`
    color: ${(props) => props.theme.colors.white};
`;
const Info = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Footer = () => {
    const { t } = useTranslation();
    const { screenViewPort } = useSelector((state: IState) => state.global);

    return (
        <FooterWrap>
            <FooterContainer>
                <LeftSide>
                    <Linking className="home-link" to={ROUTE_NAMES.HOME}>
                        <Logo />
                    </Linking>
                    <Wrap>
                        <Button href={'https://infomedia.lt/apie-mus/'} target={'_blank'}>
                            {t('about_us')}
                        </Button>
                        <Button href={'https://www.1588.lt/'} target={'_blank'}>
                            {t('project_1588')}
                        </Button>
                        <Linking to={ROUTE_NAMES.EU_PROJECTS}>{t('eu_projects')}</Linking>
                    </Wrap>
                    <Wrap>
                        <Button href={'https://infomedia.lt/karjera/'} target={'_blank'}>
                            {t('career')}
                        </Button>
                        <Button href={'https://infomedia.lt/kontaktai/'} target={'_blank'}>
                            {t('contacts')}
                        </Button>
                        <Icons>
                            <Anchor href={'https://www.facebook.com/Infomedia.lt'} target={'_blank'}>
                                <Facebook />
                            </Anchor>
                            <Anchor href={'https://www.instagram.com/infomedia.lt/'} target={'_blank'}>
                                <Instagram />
                            </Anchor>
                        </Icons>
                    </Wrap>
                </LeftSide>
                <RightSide>
                    {screenViewPort === 'mobile' && <HDivider />}
                    <Info>
                        <LogoText>
                            <Anchor href={'https://www.facebook.com/Infomedia.lt'} target={'_blank'}>
                                <InfoMediaLogo />
                            </Anchor>
                            <Text>{t('info_media')}</Text>
                        </LogoText>
                        <Logos>
                            <Divider />
                            <LextLogo />
                        </Logos>
                    </Info>
                </RightSide>
            </FooterContainer>
        </FooterWrap>
    );
};
