import classNames from 'classnames';
import styled, { css } from 'styled-components';

const Card = styled.img<{ height?: string; width?: string; maxHeight?: string }>`
    height: 100%;
    width: 100%;
    border-radius: 8px;
    ${(props) =>
        props.height &&
        css`
            height: ${props.height};
        `}
    ${(props) =>
        props.maxHeight &&
        css`
            height: ${props.maxHeight};
        `}
    ${(props) =>
        props.width &&
        css`
            width: ${props.width};
        `}
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            width: 250px;
            height: 250px;
        `}
`;

const StyledLink = styled.a`
    &.w-full {
        flex: 1;
        display: flex;
        justify-content: center;
    }
`;

interface Props {
    adUrl: string;
    adHref: string;
    customDimensions?: {
        width: string;
        height: string;
        maxHeight?: string;
    };
    fullWidth?: boolean;
}
export const AdCard = (props: Props) => {
    return (
        <StyledLink
            href={props.adHref}
            target="_blank"
            rel="noreferrer"
            className={classNames({
                'w-full': props.fullWidth,
            })}
        >
            <Card
                src={props.adUrl}
                height={props.customDimensions?.height}
                width={props.customDimensions?.width}
                maxHeight={props.customDimensions?.maxHeight}
            />
        </StyledLink>
    );
};
