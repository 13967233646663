import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { WorkingTime } from '../../../../../types/api';
import { IState } from '../../../../../store';
import { useSelector } from 'react-redux';

const WorkingHoursContainer = styled.div`
    ${(props) => css`
        margin-top: 16px;
        padding: 16px;
        background-color: ${props.theme.colors.white};
        border-radius: 8px;
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 13px;
            line-height: 18px;
        `}
    `}
`;
const WeekWrap = styled.div`
    ${(props) => css`
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: ${props.theme.colors.darkGrey};
        height: 125px;
        width: 210px;
    `}
`;
const DayWrap = styled.div`
    display: flex;
    margin-top: 8px;
`;
const Day = styled.div`
    ${(props) => css`
        font-weight: 500;
        color: ${props.theme.colors.black};
        width: 12px;
        &.english-translation {
            width: 20px;
        }
        &.weekend {
            color: ${props.theme.colors.darkGrey};
        }
    `}
`;
const Time = styled.div`
    margin-left: 8px;
`;

type Props = {
    time: WorkingTime[];
};

export const WorkingHours = (props: Props) => {
    const { t } = useTranslation();
    const { selectedLanguage } = useSelector((state: IState) => state.language);
    const weekDay = [t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday'), t('sunday')];

    const formatTime = (time: string) => {
        if (!time) {
            return t('not_working');
        }
        const date = time.split(':');
        const hours = +date[0];
        const minutes = date[1];
        const formatedTime = `${hours}.${minutes}`;
        return formatedTime;
    };

    return (
        <WorkingHoursContainer>
            <Header>{t('working_hours')}</Header>
            <WeekWrap>
                {props.time.map((day, i) => {
                    if (!day.openTime) {
                        return (
                            <React.Fragment key={i}>
                                <DayWrap>
                                    <Day
                                        className={classNames(
                                            {
                                                'english-translation': selectedLanguage === 'en',
                                            },
                                            'weekend'
                                        )}
                                    >
                                        {weekDay[i]}
                                    </Day>
                                    <Time>{t('not_working')}</Time>
                                </DayWrap>
                            </React.Fragment>
                        );
                    }
                    return (
                        <DayWrap key={i}>
                            <Day
                                className={classNames({
                                    'english-translation': selectedLanguage === 'en',
                                })}
                            >
                                {weekDay[i]}
                            </Day>
                            <Time>{`${formatTime(day.openTime)} - ${formatTime(day.closeTime)}`}</Time>
                        </DayWrap>
                    );
                })}
            </WeekWrap>
        </WorkingHoursContainer>
    );
};
