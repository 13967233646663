import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ROUTE_NAMES } from '../../constants/routes';
import { IState } from '../../store';

const BannerContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 32px;
    &.search-padding {
        padding-top: 120px;
    }
    &.gate-styles {
        padding-top: 134px;
        margin-top: -100vh;
    }
`;
const BannerWrap = styled.div`
    max-width: 1254px;
    margin: 0 16px 32px;
    border: 1px solid ${(props) => props.theme.colors.outlineGrey};
    min-height: 116px;
    width: calc(100% - 32px);
`;
const Banner = styled.img`
    object-fit: fill;
    height: 100%;
    width: 100%;
`;

type Props = {
    imgUrl?: string;
    imgHref?: string;
};

export const HorizontalBanner = (props: Props) => {
    const { bannersList, headerSearchOpen, gateIsActive, bannersFilteredByTopic, bannersFilteredByKeyword } =
        useSelector((state: IState) => state.global);
    const location = useLocation();
    const companyListUrlChecker = location.pathname.split('/');

    const [banner, setBanner] = useState({
        imgUrl: props.imgUrl || '',
        imgHref: props.imgHref || '',
    });

    useEffect(() => {
        if (bannersFilteredByTopic.WBR.length > 0) {
            setBanner({
                imgUrl: bannersFilteredByTopic.WBR[0].image,
                imgHref: bannersFilteredByTopic.WBR[0].url,
            });
            return;
        }

        if (bannersFilteredByKeyword.WBZ.length > 0) {
            setBanner({
                imgUrl: bannersFilteredByKeyword.WBZ[0].image,
                imgHref: bannersFilteredByKeyword.WBZ[0].url,
            });

            return;
        }

        if (bannersList.WBP.length > 0) {
            setBanner({
                imgUrl: bannersList.WBP[0].image,
                imgHref: bannersList.WBP[0].url,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersList.WBP, bannersFilteredByTopic, bannersFilteredByKeyword]);

    return (
        <BannerContainer
            className={classNames({
                'search-padding': !headerSearchOpen,
                'gate-styles':
                    gateIsActive &&
                    companyListUrlChecker.length < 5 &&
                    (location.pathname === ROUTE_NAMES.HOME || companyListUrlChecker[1] === 'imoniu-sarasas') &&
                    companyListUrlChecker[2] !== 'imones-kortele',
            })}
        >
            <BannerWrap>
                {banner.imgUrl && (
                    <a href={banner.imgHref} target="_blank" rel="noreferrer">
                        <Banner src={banner.imgUrl} />
                    </a>
                )}
            </BannerWrap>
        </BannerContainer>
    );
};
