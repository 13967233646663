import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { CompanyResponse } from '../../../../types/api';
import { Branches } from './components/Branches';
import { BrandsRepresented } from './components/BrandsRepresented';
import { CompanyCodes } from './components/CompanyCodes';
import { Description } from './components/Description';
import { Manager } from './components/Manager';
import { Reviews } from './components/Reviews';
import { RelatedTopics } from './components/RelatedTopics';
import { WorkingHours } from './components/WorkingHours';
import { BankAccounts } from './components/BankAccounts';
import { Slider } from '../../../Slider/Slider';
import { RelatedHeads } from './components/RelatedHeads';

const InformationContainer = styled.div``;
const Header = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    margin: 24px 0 16px 0;
`;
const InformationWrap = styled.div`
    ${(props) => css`
        padding: 16px;
        background-color: ${props.theme.colors.greyBg};
    `}
`;
const HoursReviewManager = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        ${props.theme.screen.mobile &&
        css`
            grid-template-columns: 1fr;
            gap: 0;
        `}
    `}
`;

const Gallery = styled.div`
    margin-top: 16px;
`;

export const Information = (props: CompanyResponse) => {
    const { t } = useTranslation();
    return (
        <InformationContainer>
            <Header>{t('information')}</Header>
            <InformationWrap>
                <CompanyCodes data={props.data} />
                <Description data={props.data} />
                {props.data.manager && <Manager data={props.data} />}
                <HoursReviewManager>
                    {props.data.workingTime?.length > 0 && <WorkingHours time={props.data.workingTime} />}
                    <Reviews data={props.data} />
                    {props.data.bankAccounts?.length > 0 && <BankAccounts bankAccounts={props.data.bankAccounts} />}
                </HoursReviewManager>
                {props.data.activities.length > 0 && <RelatedHeads heads={props.data.activities} />}
                {props.data.galleries.length > 0 && (
                    <Gallery>
                        <Slider
                            amountPerScreen={{
                                desktop: 3,
                                tablet: 2,
                                mobile: 2,
                            }}
                            logoUrls={props.data.galleries.flatMap((item) => item.logoPath)}
                            isInfoGallery={true}
                        />
                    </Gallery>
                )}
                {props.data.trademarkImages?.length > 0 && (
                    <BrandsRepresented trademarkImages={props.data.trademarkImages} />
                )}
                {props.data.childCompanies?.length > 0 && <Branches childCompanies={props.data.childCompanies} />}
            </InformationWrap>
        </InformationContainer>
    );
};
