import classNames from 'classnames';
import styled, { css } from 'styled-components';

const LogoContainer = styled.img`
    ${(props) => css`
        width: 100%;
        height: 182px;
        object-fit: contain;
        ${props.theme.screen.tablet &&
        css`
            height: 160px;
        `}
        ${props.theme.screen.mobile &&
        css`
            height: 148px;
        `}
        &.weekly-companies {
            object-fit: cover;
        }
        &.info-gallery {
            width: 100%;
        }
    `}
`;

const Anchor = styled.a`
    ${(props) => css`
        width: 14.5%;
        ${props.theme.screen.tablet &&
        css`
            width: 22.1%;
        `}
        ${props.theme.screen.mobile &&
        css`
            width: 47.5%;
        `}
        &.gallery-size {
            width: 100%;
            ${props.theme.screen.mobile &&
            css`
                width: 47.5%;
            `}
        }
        &.info-gallery {
            width: 100%;
        }
    `}
`;

interface WeeklyCompanyLogo {
    logoPath: string;
    url: string;
}

type Props = {
    adjustSize?: boolean;
    img: string | WeeklyCompanyLogo;
    weeklyCompanies?: boolean;
    isInfoGallery?: boolean;
};

export const Logos = (props: Props) => {
    let imgSrc: string = '';
    let linkUrl: string | undefined = undefined;

    if (props.img && typeof props.img !== 'string') {
        imgSrc = props.img.logoPath;
        linkUrl = props.img.url;
    } else if (typeof props.img === 'string') {
        imgSrc = props.img;
    }

    return (
        <Anchor
            href={linkUrl}
            className={classNames({ 'gallery-size': props.adjustSize, 'info-gallery': props.isInfoGallery })}
        >
            <LogoContainer
                src={imgSrc}
                className={classNames({
                    'weekly-companies': !props.weeklyCompanies,
                    'info-gallery': props.isInfoGallery,
                })}
            />
        </Anchor>
    );
};
