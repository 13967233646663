import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { getArticle } from '../../services/articles.service';
import { IState } from '../../store';
import { setBreadcrumb } from '../../store/global.slice';
import { Article, CompanyListItem, CompanySearchFilters } from '../../types/api';
import { AdCard } from '../AdCard/AdCard';
import { Slider } from '../Slider/Slider';

import { BusinessListCard } from '../BusinessListCard/BusinessListCard';
import { useTranslation } from 'react-i18next';
import { getSearchCompanies } from '../../services/company.service';

const ArticlesContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;
const ArticleContainerWrap = styled.div`
    width: 1254px;
    margin: 0 16px;
`;
const ArticleInnerContainer = styled.div`
    display: flex;
    gap: 2.5%;
`;

const ArticleWrap = styled.div`
    display: grid;
    width: 74.5%;
    height: fit-content;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        `}
`;
const HeaderContainer = styled.div`
    align-items: center;
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: 0.01em;
        margin: 8px 0 32px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            margin-bottom: 0;
        `}
    `}
`;
const Category = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.outlineGrey};
        color: ${props.theme.colors.darkGrey};
        margin: 0 16px 8px 0;
        padding: 6px 12px;
        border-radius: 8px;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0.05em;
        max-height: 26px;
        width: fit-content;
        cursor: pointer;
    `}
`;
const Img = styled.img`
    ${(props) => css`
        height: 100%;
        width: 100%;
        border-radius: 8px;
        max-height: 622px;
        object-fit: contain;
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 24px;
        `}
    `}
`;
const Date = styled.div`
    ${(props) => css`
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.005em;
        margin: 32px 0 16px 0;
        width: 100%;
        ${!props.theme.screen.desktop &&
        css`
            margin: 24px 0 16px 0;
        `}
    `}
`;
const Html = styled.div``;
const SideBar = styled.div`
    ${(props) => css`
        width: 23%;
        ${!props.theme.screen.desktop &&
        css`
            display: flex;
            justify-content: center;
            width: 100%;
        `}
    `}
`;

const AdsWrapper = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: 1fr;
        height: fit-content;
        gap: 16px;
        padding: 16px;
        width: 23%;
        border: 1px solid ${props.theme.colors.outlineGrey};
        &.full-width {
            width: unset;
        }
        ${!props.theme.screen.desktop &&
        css`
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 282px;
        `}
    `}
`;
const SliderWrap = styled.div`
    ${(props) =>
        css`
            margin: 48px 0 0;
            padding: 48px 16px;
            background-color: ${props.theme.colors.greyBg};
        `}
`;
const CategoriesWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
`;

const BusinessWrapper = styled.div`
    display: flex;
    justify-content: center;
`;
const BusinessContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 1254px;
    margin: 0 16px;
`;
const BusinessHeader = styled.div`
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    margin: 43px 0;
`;

const HeaderWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Hero = styled.div`
    ${(props) => css`
        width: 100%;
        display: flex;
        gap: 30px;
        min-height: calc(100vh - 523px);
        margin-bottom: 32px;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;

const BusinessCardWrap = styled.div`
    ${(props) => css`
        display: flex;
        flex-direction: column;
        width: 74.5%;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ArticleInner = () => {
    const dispatch = useDispatch();
    const [article, setArticle] = useState<Article>();
    const [similarBusinesses, setSimilarBusinesses] = useState<CompanyListItem[]>([]);
    const { t } = useTranslation();
    const { screenViewPort, bannersList, loading } = useSelector((state: IState) => state.global);
    const { categoriesList } = useSelector((state: IState) => state.categories);
    const navigate = useNavigate();
    const urlParams = useParams();

    useEffect(() => {
        if (urlParams.cardId) {
            getArticle(parseInt(urlParams.cardId)).then((resp) => {
                setArticle(resp);
                dispatch(
                    setBreadcrumb({
                        key: 'articleName',
                        value: resp.name,
                    })
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const filters: CompanySearchFilters = {};
        if (article?.topics) {
            filters.topic = article.topics[0]?.id.toString();
            getSearchCompanies(1, filters).then((res) => {
                const response = res.data.slice(0, 3);
                setSimilarBusinesses(response);
            });
        }
    }, [article?.topics]);

    const navigateToTopic = (topicId: number, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const category = categoriesList.find((category) => category.topics.find((topic) => topic.id === topicId));
        if (category) {
            navigate(`/imoniu-sarasas/${category.id}/${topicId}`);
        }
    };

    return (
        <>
            <ArticlesContainer>
                {article && (
                    <ArticleContainerWrap>
                        <HeaderContainer>
                            {article.topics?.length > 0 && (
                                <CategoriesWrap>
                                    {article.topics.map((topic, i) => (
                                        <Category key={i} onClick={(e) => navigateToTopic(topic.id, e)}>
                                            {topic.name}
                                        </Category>
                                    ))}
                                </CategoriesWrap>
                            )}
                            <Header>{article.name}</Header>
                        </HeaderContainer>
                        <ArticleInnerContainer>
                            <ArticleWrap>
                                {article.banner && <Img src={article.banner} />}
                                <Date>{article.postDate}</Date>
                                <Html dangerouslySetInnerHTML={{ __html: article.content }} />
                            </ArticleWrap>
                            {screenViewPort === 'desktop' && (
                                <SideBar>
                                    <AdsWrapper className="full-width">
                                        {bannersList.WBM.length > 0 &&
                                            bannersList.WBM.slice(0, 5).map((banner, index) => {
                                                return <AdCard key={index} adUrl={banner.image} adHref={banner.url} />;
                                            })}
                                    </AdsWrapper>
                                </SideBar>
                            )}
                        </ArticleInnerContainer>
                    </ArticleContainerWrap>
                )}
            </ArticlesContainer>
            {!loading && article && article?.articleImages.length > 0 && (
                <SliderWrap>
                    <Slider
                        logoUrls={article?.articleImages}
                        amountPerScreen={{ desktop: 4, tablet: 4, mobile: 2 }}
                        gallerySize={true}
                    />
                </SliderWrap>
            )}
            {similarBusinesses.length > 0 && (
                <BusinessWrapper>
                    <BusinessContainer>
                        <HeaderWrap>
                            <BusinessHeader>{t('related_companies')}</BusinessHeader>
                        </HeaderWrap>
                        <Hero>
                            <BusinessCardWrap>
                                {similarBusinesses.map((company, index) => (
                                    <Wrapper key={index}>
                                        <BusinessListCard key={index} company={company} />
                                    </Wrapper>
                                ))}
                            </BusinessCardWrap>
                            {screenViewPort === 'desktop' && (
                                <SideBar>
                                    <AdsWrapper className="full-width">
                                        {bannersList.WBM.length > 0 &&
                                            bannersList.WBM.slice(0, 3).map((banner, index) => {
                                                return <AdCard key={index} adUrl={banner.image} adHref={banner.url} />;
                                            })}
                                    </AdsWrapper>
                                </SideBar>
                            )}
                        </Hero>
                    </BusinessContainer>
                </BusinessWrapper>
            )}
        </>
    );
};
