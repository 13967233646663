import styled, { css } from 'styled-components';
import { useEffect, useRef } from 'react';
import { CardTop } from './components/CardTop';
import { CardMid } from './components/CardMid';
import { CardBottom } from './components/CardBottom';
import { useNavigate } from 'react-router-dom';
import { CompanyListItem } from '../../types/api';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderSearchOpen } from '../../store/global.slice';
import classNames from 'classnames';
import { IState } from '../../store';
import debounce from 'lodash.debounce';

const Card = styled.div`
    width: 100%;
    margin-bottom: 32px;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
    }
    &.active {
        box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
    }
`;
const CardContainer = styled.div`
    ${(props) => css`
        border: 1px solid ${props.theme.colors.outlineGrey};
        &.boosted-position {
            border: 1px solid ${props.theme.colors.darkYellow};
        }
    `}
`;

interface Props {
    company: CompanyListItem;
    setCardInView?: (company: CompanyListItem) => void;
}

export const BusinessListCard = (props: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { screenViewPort } = useSelector((state: IState) => state.global);

    useEffect(() => {
        dispatch(setHeaderSearchOpen(true));
        calculateShownCard();
        if (screenViewPort !== 'mobile') {
            window.addEventListener('scroll', debouncedCalculateShownCard);
        }
        return () => {
            window.removeEventListener('scroll', debouncedCalculateShownCard);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const calculateShownCard = () => {
        if (!ref.current) {
            return;
        }
        const refTop = ref.current.getBoundingClientRect().top;
        const refBot = ref.current.getBoundingClientRect().bottom;

        if (props.setCardInView && ((refBot > 150 && refBot < 600) || (refTop > 150 && refTop < 600))) {
            props.setCardInView(props.company);
        }
    };

    const debouncedCalculateShownCard = debounce(calculateShownCard, 250);

    const cardInnerPage = () => {
        navigate(`/imones-kortele/${props.company.id}`);
    };

    return (
        <Card ref={ref}>
            <CardContainer
                className={classNames({ 'boosted-position': props.company.boostedPosition })}
                onClick={cardInnerPage}
            >
                <CardTop data={props.company} />
                <CardMid data={props.company} />
                <CardBottom topics={props.company.topics} />
            </CardContainer>
        </Card>
    );
};
