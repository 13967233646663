import { connect } from 'react-redux';
import { IState } from '../../../store';

const PureArticleNameBreadcrumb = ({ name }: { name: string }) => {
    return <span>{name}</span>;
};

const mapStateToProps = (state: IState, props: any) => {
    const articleName = state.global.breadcrumbs.articleName;

    return { name: articleName || '...' };
};

export default connect(mapStateToProps)(PureArticleNameBreadcrumb);
