import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { CompanyResponse } from '../../../../../types/api';
import { Stars } from '../../../../Stars/Stars';

const ReviewsContainer = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.white};
        border-radius: 8px;
        padding: 16px;
        margin-top: 16px;
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 14px;
        letter-spacing: 0.005em;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 8px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 13px;
            line-height: 18px;
        `}
    `}
`;
const Text = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 18px;
`;
const Review = styled.div`
    ${(props) => css`
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        margin-top: 18px;
        color: ${props.theme.colors.darkYellow};
        text-decoration: underline;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        text-underline-offset: 3px;
        width: fit-content;
        cursor: pointer;
        &:hover {
            font-weight: 700;
        }
    `}
`;

export const Reviews = (props: CompanyResponse) => {
    const { t } = useTranslation();

    const goToreviews = () => {
        window.open(`${process.env.REACT_APP_IMONES_URL}/imones-kortele/${props.data.id}`, '_blank');
    };

    return (
        <ReviewsContainer>
            <Header>{t('reviews')}</Header>
            <Text>{t('overall_rating_of_the_company')}</Text>
            <Stars rating={props.data.rating} feedbackCount={props.data.feedbackCount} />
            <Review onClick={() => goToreviews()}>{t('write_a_review')}</Review>
        </ReviewsContainer>
    );
};
