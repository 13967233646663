import { createSlice } from '@reduxjs/toolkit';
import { initialCategoriesState, ICategoriesState, ICategory } from './types';

const categoriesSlice = createSlice({
    name: 'topics',
    initialState: initialCategoriesState,
    reducers: {
        setCategoriesList: (state: ICategoriesState, action) => {
            const sortedList = action.payload.sort((a: ICategory, b: ICategory) => a.name.localeCompare(b.name));
            state.categoriesList = sortedList;
        },
        setTopicsItem: (state: ICategoriesState, action) => {
            state.topics[action.payload.id] = action.payload.topics;
        },
    },
});

export const { setCategoriesList, setTopicsItem } = categoriesSlice.actions;

export default categoriesSlice.reducer;
