import classNames from 'classnames';
import styled, { css } from 'styled-components';
import { CompanyListItem } from '../../../types/api';

const TitleContainer = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 19px 16px;
        color: ${props.theme.colors.white};
        background-color: ${props.theme.colors.black};
        ${props.theme.screen.mobile &&
        css`
            padding: 10px 8px;
        `}
        &.boosted-position {
            background-color: ${props.theme.colors.darkerYellow};
        }
    `}
`;
const Title = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 15px;
            line-height: 18px;
        `}
    `}
`;
const Header = styled.div`
    display: flex;
    align-items: center;
`;
interface Props {
    data: CompanyListItem;
}

export const CardTop = (props: Props) => {
    return (
        <>
            <TitleContainer className={classNames({ 'boosted-position': props.data.boostedPosition })}>
                <Header>
                    <Title>{props.data.name}</Title>
                </Header>
            </TitleContainer>
        </>
    );
};
