import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { IState } from '../../../store';
import { ArticlesPreview, BannerItem } from '../../../types/api';
import { AdCard } from '../../AdCard/AdCard';
import { ArticleCard } from './ArticleCard';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../../../pages/ArticlesPage/Pagination';

const ArticlesAndSidebarWrap = styled.div`
    display: flex;
    gap: 2.5%;
`;
const Hero = styled.div`
    ${(props) => css`
        width: 74.5%;
        padding: 32px 0 48px 0;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `};
    `}
`;
const ArticleWrap = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px 2.5%;
        ${!props.theme.screen.desktop &&
        css`
            display: flex;
            flex-wrap: wrap;
            gap: 0 2.5%;
        `}
    `}
`;
const Article = styled(ArticleCard)``;
const SideBar = styled.div`
    ${(props) => css`
        width: 23%;
        ${!props.theme.screen.desktop &&
        css`
            display: flex;
            justify-content: center;
            width: 100%;
        `}
    `}
`;
const AdsWrapper = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.white};
        margin: 30px 0;
        display: grid;
        grid-template-columns: 100%;
        gap: 16px;
        padding: 16px;
        border: 1px solid ${props.theme.colors.outlineGrey};
        max-width: 282px;
        ${!props.theme.screen.desktop &&
        css`
            margin: 24px 0;
        `}
    `}
`;
const ExpandedRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const NoArticlesText = styled.div`
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0.01em;
    margin: 16px 0 24px;
`;

type Props = {
    adAmount: number;
    articleData: ArticlesPreview[] | undefined;
    setArticles?: (articles: ArticlesPreview[]) => void;
};

export const ArticlesAndSidebar = (props: Props) => {
    const { screenViewPort, bannersList } = useSelector((state: IState) => state.global);
    const location = useLocation();
    const [t] = useTranslation();

    const bannersSlice = () => {
        if (location.pathname === '/straipsniai') {
            return bannersList.WBM.slice(0, 5);
        }
        return bannersList.WBM.slice(3, 5);
    };

    return (
        <ArticlesAndSidebarWrap>
            <Hero>
                {props.articleData && props.articleData.length > 0 ? (
                    <>
                        <ArticleWrap>
                            {props.articleData &&
                                props.articleData.map((article, index: number) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Article key={article.id} {...article} />
                                            {Number.isInteger((index + 1) / 2) &&
                                                bannersList.WBM[(index + 1) / 2 - 1] &&
                                                screenViewPort !== 'desktop' && (
                                                    <ExpandedRow>
                                                        <AdsWrapper>
                                                            <AdCard
                                                                adUrl={bannersList.WBM[(index + 1) / 2 - 1].image}
                                                                adHref={bannersList.WBM[(index + 1) / 2 - 1].url}
                                                            />
                                                        </AdsWrapper>
                                                    </ExpandedRow>
                                                )}
                                        </React.Fragment>
                                    );
                                })}
                        </ArticleWrap>
                        {props.articleData && props.articleData.length > 0 && props.setArticles && (
                            <Pagination setArticles={props.setArticles} />
                        )}
                    </>
                ) : (
                    <NoArticlesText>{t('no_articles')}</NoArticlesText>
                )}
            </Hero>
            {screenViewPort === 'desktop' && bannersSlice().length > 0 && (
                <SideBar>
                    <AdsWrapper>
                        {bannersSlice().map((ad: BannerItem, index: number) => {
                            if (index + 1 <= props.adAmount) {
                                return <AdCard adUrl={ad.image} adHref={ad.url} key={index} />;
                            }
                            return null;
                        })}
                    </AdsWrapper>
                </SideBar>
            )}
        </ArticlesAndSidebarWrap>
    );
};
