import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ArticlesAndSidebar } from '../../components/Articles/ArticlesAndSidebar/ArticlesAndSidebar';
import { HeaderWithCategories } from '../../components/Articles/HeaderWithCategories/HeaderWithCategories';
import { Categories } from '../../components/Categories/Categories';
import { getArticlesList } from '../../services/articles.service';
import { ArticlesPreview } from '../../types/api';
import { WeeklyCompanyList } from '../../components/WeeklyCompanyList/WeeklyCompanyList';

const LandingPageWrapper = styled.div``;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 16px;
`;

const ArticlesContainer = styled.div`
    ${(props) => css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: ${props.theme.colors.greyBg};
    `}
`;
const Wrap = styled.div`
    width: 1254px;
    margin: 0 16px;
`;

export const LandingPage = () => {
    const [allArticles, setAllArticles] = useState<ArticlesPreview[]>([]);

    useEffect(() => {
        if (allArticles?.length > 0) {
            return;
        }
        getArticlesList().then((resp) => {
            const articlesWithContent = resp.data.filter((article) => article.contentTeaser);
            setAllArticles(articlesWithContent);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LandingPageWrapper>
            <Content>
                <Categories />
            </Content>
            {allArticles.length > 0 && (
                <ArticlesContainer>
                    <Wrap>
                        <HeaderWithCategories />
                    </Wrap>
                    <Wrap>
                        <ArticlesAndSidebar articleData={allArticles.slice(0, 3)} adAmount={2} />
                    </Wrap>
                </ArticlesContainer>
            )}
            <WeeklyCompanyList />
        </LandingPageWrapper>
    );
};
