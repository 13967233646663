import { useState } from 'react';
import styled, { css } from 'styled-components';
import southIcon from '@mui/icons-material/South';
import { useTranslation } from 'react-i18next';
import { BankAccount } from '../../../../../types/api';
import React from 'react';

const BankAccountsContainer = styled.div`
    ${(props) => css`
        margin-top: 16px;
        border-radius: 8px;
        background-color: ${props.theme.colors.white};
        padding: 16px;
    `}
`;
const Header = styled.div`
    margin-bottom: 16px;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.005em;
`;
const Accounts = styled.div`
    display: block;
    gap: 16px;
`;

const Name = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.005em;
`;
const Text = styled.div`
    margin-top: 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.005em;
`;

const Button = styled.div`
    ${(props) => css`
        margin-top: 18px;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: ${props.theme.colors.darkYellow};
        text-decoration: underline;
        text-transform: uppercase;
        border: none;
        background-color: transparent;
        padding: 0;
        display: flex;
        align-items: center;
        text-underline-offset: 3px;
        width: fit-content;
        cursor: pointer;
        &:hover {
            font-weight: 700;
        }
    `}
`;
const SouthIcon = styled(southIcon)`
    ${(props) => css`
        color: ${props.theme.colors.darkYellow};
        margin-left: 8px;
        height: 18px !important;
        width: 18px !important;
        &.show-more {
            rotate: 180deg;
        }
    `}
`;
const ShowMoreText = styled.div``;

const Divider = styled.div`
    height: 1px;
    background-color: ${(props) => props.theme.colors.outlineGrey};
    width: 100%;
    margin: 16px 0;
`;

type BankAcc = {
    bankAccounts: BankAccount[];
};

export const BankAccounts = (props: BankAcc) => {
    const [showMore, setShowMore] = useState(false);
    const { t } = useTranslation();

    const toggleShowMore = () => {
        setShowMore((current) => !current);
    };

    const accountSlice = (branches: BankAccount[]) => {
        return branches && branches.length > 2 && !showMore ? branches.slice(0, 2) : branches;
    };

    return (
        <BankAccountsContainer>
            <Header>{t('bank_accounts')}</Header>
            <Accounts>
                {props.bankAccounts.length > 0 &&
                    accountSlice(props.bankAccounts).map((account, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Name>{account.bankName}</Name>
                                <Text>{account.accountNumber}</Text>
                                {index !== accountSlice(props.bankAccounts).length - 1 && <Divider />}
                            </React.Fragment>
                        );
                    })}
            </Accounts>
            {props.bankAccounts.length > 2 && (
                <Button onClick={toggleShowMore}>
                    <ShowMoreText>{!showMore ? t('more') : t('less')}</ShowMoreText>
                    <SouthIcon className={showMore ? 'show-more' : ''} />
                </Button>
            )}
        </BankAccountsContainer>
    );
};
