import { t } from 'i18next';
import styled, { css } from 'styled-components';
import { CompanyResponse } from '../../../../../types/api';

const CompanyCodesContainer = styled.div`
    ${(props) => css`
        display: flex;
        background-color: ${props.theme.colors.white};
        border-radius: 8px;
        ${props.theme.screen.mobile &&
        css`
            flex-wrap: wrap;
            background-color: transparent;
            gap: 8px;
        `}
    `}
`;
const Wrap = styled.div``;

const Name = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
`;
const Info = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    margin-top: 8px;
`;
const TwoBlocks = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        width: 50%;
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
            background-color: ${props.theme.colors.white};
            border-radius: 8px;
        `}
    `}
`;
const Divider = styled.div`
    ${(props) => css`
        width: 1px;
        height: 66%;
        background-color: ${props.theme.colors.outlineGrey};
        ${props.theme.screen.mobile &&
        css`
            &.mobile-hide {
                display: none;
            }
        `}
    `}
`;
const Container = styled.div`
    ${(props) => css`
        width: calc(50% - 48px);
        background-color: ${props.theme.colors.white};
        padding: 24px;
        &.first {
            border-radius: 8px 0 0 8px;
        }
        &.fourth {
            border-radius: 0 8px 8px 0;
        }
        ${props.theme.screen.mobile &&
        css`
            &.third {
                border-radius: 8px 0 0 8px;
            }
            &.second {
                border-radius: 0 8px 8px 0;
            }
        `}
    `}
`;

export const CompanyCodes = (props: CompanyResponse) => {
    const companyCodeValidator = (code: string) => {
        if (code.length === 11 && +code[0] > 2 && +code[0] < 7) {
            return <Info>{t('no_information_provided')}</Info>;
        } else {
            return <Info>{props.data.companyCode}</Info>;
        }
    };

    return (
        <CompanyCodesContainer>
            {(props.data.companyCode || props.data.vatCode) && (
                <TwoBlocks>
                    {props.data.companyCode && (
                        <>
                            <Container className="first">
                                <Wrap>
                                    <Name>{t('company_code')}</Name>
                                    {props.data.companyCode && companyCodeValidator(props.data.companyCode)}
                                </Wrap>
                            </Container>
                            <Divider />
                        </>
                    )}
                    {props.data.vatCode && (
                        <>
                            <Container className="second">
                                <Wrap>
                                    <Name>{t('vat_code')}</Name>
                                    <Info>{props.data.vatCode}</Info>
                                </Wrap>
                            </Container>
                            <Divider className="mobile-hide" />
                        </>
                    )}
                </TwoBlocks>
            )}

            {(props.data.registrationDate || props.data.insurerCode) && (
                <TwoBlocks>
                    {props.data.registrationDate && (
                        <>
                            <Container className="third">
                                <Wrap>
                                    <Name>{t('registration_date')}</Name>
                                    <Info>{props.data.registrationDate}</Info>
                                </Wrap>
                            </Container>
                            <Divider />
                        </>
                    )}
                    {props.data.insurerCode && (
                        <Container className="fourth">
                            <Wrap>
                                <Name>{t('health_insurer_code')}</Name>
                                <Info>{props.data.insurerCode}</Info>
                            </Wrap>
                        </Container>
                    )}
                </TwoBlocks>
            )}
        </CompanyCodesContainer>
    );
};
