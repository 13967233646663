import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Link, useLocation } from 'react-router-dom';
import { routes } from '../../constants/routes';
import classnames from 'classnames';
import { ReactComponent as ChevronRight } from '../../assets/images/BreadCrumbs/chevron.svg';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { IState } from '../../store';

const BreadCrumbsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 8px;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            padding-bottom: 0;
        `}
`;
const BreadCrumbsHolder = styled.div`
    width: 1254px;
    margin: 0 16px;
    display: flex;
`;
const BreadCrumbItem = styled(Link)`
    ${(props) => css`
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0.005em;
        display: flex;
        align-items: center;
        text-decoration: none;
        color: ${props.theme.colors.outlineAndPlaceholder};
        fill: ${props.theme.colors.outlineAndPlaceholder};
        &.active {
            color: ${props.theme.colors.black};
        }
    `}
`;
const Chevron = styled(ChevronRight)`
    padding: 0 15px;
`;
const StyledMuiBreadcrumbs = styled(MuiBreadcrumbs)`
    .MuiButtonBase-root {
        background-color: ${(props) => props.theme.colors.white};
    }
`;

export const Breadcrumbs = () => {
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const breadcrumbs = useBreadcrumbs(routes);
    const location = useLocation();

    return (
        <BreadCrumbsWrapper>
            <BreadCrumbsHolder>
                <StyledMuiBreadcrumbs separator={<Chevron />} maxItems={screenViewPort === 'desktop' ? 4 : 2}>
                    {breadcrumbs.map(({ match, breadcrumb }, index) => {
                        if (
                            match.params?.topicId === 'imones-kortele' ||
                            match.params?.categoryId === 'imones-kortele'
                        ) {
                            return null;
                        }
                        return (
                            <BreadCrumbItem
                                key={index}
                                to={match.pathname}
                                className={classnames({
                                    active: match.pathname === location.pathname,
                                })}
                            >
                                {breadcrumb}
                            </BreadCrumbItem>
                        );
                    })}
                </StyledMuiBreadcrumbs>
            </BreadCrumbsHolder>
        </BreadCrumbsWrapper>
    );
};
