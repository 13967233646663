import { slide as Menu } from 'react-burger-menu';
import { ReactComponent as Logo } from '../../assets/images/Header/white_logo.svg';
import styled, { css } from 'styled-components';
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ROUTE_NAMES } from '../../constants/routes';
import { useState } from 'react';

const BurgerMenuContainer = styled.div`
    ${(props) => css`
        /* To hide burger menu bars under mobile search module */
        position: absolute;
        z-index: 1301;
        .bm-menu-wrap {
            top: 0;
            left: 0;
        }
        .bm-burger-button {
            position: fixed;
            width: 18px;
            height: 12px;
            right: 16px;
            top: 36px;
        }
        .bm-overlay {
            top: 0px;
            left: 0px;
            background-color: ${props.theme.colors.black};
        }
        .bm-menu {
            background: ${props.theme.colors.black};
            font-size: 1.15em;
        }
        .bm-item {
            padding: 16px 16px 0 16px;
            color: ${props.theme.colors.white};
        }
        .bm-cross,
        .bm-burger-bars {
            background-color: ${props.theme.colors.black};
        }
        .bm-cross.cross-line {
            background-color: ${props.theme.colors.white};
            width: 2px !important;
            height: 17px !important;
        }
        .languages {
            display: flex !important;
            justify-content: center;
        }
    `}
`;
const BusinessLogo = styled(Logo)`
    margin: 0 0 59px 0;
`;
const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
`;
const NavigationLink = styled(NavLink)`
    ${(props) => css`
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        text-decoration: none;
        margin-bottom: 46px;
        color: ${props.theme.colors.white};
    `}
`;

export const BurgerMenu = () => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(false);

    const closeSideBar = () => {
        setOpen(false);
    };

    return (
        <BurgerMenuContainer>
            <Menu width={301} isOpen={isOpen} onOpen={() => setOpen(!isOpen)} onClose={() => setOpen(!isOpen)}>
                <BusinessLogo />
                <NavigationLink to={ROUTE_NAMES.HOME} onClick={closeSideBar}>
                    {t('main_page')}
                </NavigationLink>
                <NavigationLink to={ROUTE_NAMES.ARTICLE_PAGE} onClick={closeSideBar}>
                    {t('articles')}
                </NavigationLink>
                <NavigationLink to={ROUTE_NAMES.EU_PROJECTS} onClick={closeSideBar}>
                    {t('eu_projects')}
                </NavigationLink>
                <ItemWrapper className="languages">
                    <LanguageSwitcher />
                </ItemWrapper>
            </Menu>
        </BurgerMenuContainer>
    );
};
