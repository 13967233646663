import styled, { css } from 'styled-components';

import eastIcon from '@mui/icons-material/East';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Topic } from '../../../types/api';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';
import { ICategoriesState } from '../../../store/categories/types';

const CardContainer = styled.div`
    ${(props) => css`
        text-decoration: none;
        color: unset;
        display: flex;
        ${!props.theme.screen.desktop &&
        css`
            width: 48.7%;
        `}
    `}
`;
const CardWrap = styled.div`
    ${(props) => css`
        padding: 16px 16px 24px;
        width: 100%;
        background-color: ${props.theme.colors.white};
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    `}
`;
const Img = styled.img`
    ${(props) => css`
        height: 177px;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
        ${props.theme.screen.mobile &&
        css`
            height: 82px;
        `}
    `}
`;
const Category = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.outlineGrey};
        color: ${props.theme.colors.darkGrey};
        padding: 6px 12px;
        width: fit-content;
        border-radius: 8px;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0.05em;
        margin: 0 8px 8px 0;
        cursor: pointer;
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0.005em;
        margin: 8px 0;
        word-break: break-word;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 15px;
            line-height: 18px;
        `}
    `}
`;
const Date = styled.div`
    ${(props) => css`
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.005em;
        margin-bottom: 8px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 10px;
            line-height: 12px;
        `}
    `}
`;
const Text = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.005em;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 13px;
            line-height: 18px;
        `}
    `}
`;
const Button = styled(Link)`
    ${(props) => css`
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        margin-top: 26px;
        color: ${props.theme.colors.darkYellow};
        text-decoration: underline;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        text-underline-offset: 3px;
        width: fit-content;
        cursor: pointer;
        &:hover {
            font-weight: 700;
        }
    `}
`;
const EastIcon = styled(eastIcon)`
    color: ${(props) => props.theme.colors.darkYellow};
    margin-left: 8px;
`;
const ImgWrap = styled.div`
    margin-bottom: 24px;
`;
const Wrap = styled.div``;
const CategoriesWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

type Props = {
    id: number;
    banner: string | null;
    topics: Topic[];
    name: string;
    postDate: string;
    contentTeaser: string;
};

export const ArticleCard = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { categoriesList } = useSelector((state: IState) => state.categories);

    const navigateToTopic = (topicId: number, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const category = categoriesList.find((category) => category.topics.find((topic) => topic.id === topicId));
        if (category) {
            navigate(`/imoniu-sarasas/${category.id}/${topicId}`);
        }
    };

    return (
        <CardContainer>
            <CardWrap>
                <Wrap>
                    {props.banner && (
                        <ImgWrap>
                            <Img src={props.banner} />
                        </ImgWrap>
                    )}
                    {props.topics?.length > 0 && (
                        <CategoriesWrap>
                            {props.topics.map((topic: Topic) => (
                                <Category key={topic.id} onClick={(e) => navigateToTopic(topic.id, e)}>
                                    {topic.name}
                                </Category>
                            ))}
                        </CategoriesWrap>
                    )}
                    <Header>{props.name}</Header>
                    <Date>{props.postDate}</Date>
                    <Text>
                        {props.contentTeaser?.length > 250
                            ? `${props.contentTeaser.substring(0, 250)}...`
                            : props.contentTeaser}
                    </Text>
                </Wrap>
                <Button to={`/straipsniai/${props.id}`}>
                    {t('read')}
                    <EastIcon fontSize="small" />
                </Button>
            </CardWrap>
        </CardContainer>
    );
};
