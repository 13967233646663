import styled, { css } from 'styled-components';
import { t } from 'i18next';
import { ChildCompanies } from '../../../../../types/api';
import { useSelector } from 'react-redux';
import { IState } from '../../../../../store';
import { useState } from 'react';

import southIcon from '@mui/icons-material/South';
import { NumberFormater } from '../../../../NumberFormater/NumberFormater';

const BranchesContainer = styled.div`
    ${(props) => css`
        margin-top: 16px;
        background-color: ${props.theme.colors.white};
        padding: 16px;
        border-radius: 8px;
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0.005em;
        ${props.theme.screen.mobile &&
        css`
            font-size: 13px;
            line-height: 18px;
        `}
    `}
`;
const BranchesWrap = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-top: 8px;
        ${props.theme.screen.mobile &&
        css`
            display: block;
        `}
    `}
`;
const Button = styled.div`
    ${(props) => css`
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: ${props.theme.colors.darkYellow};
        text-decoration: underline;
        text-transform: uppercase;
        border: none;
        background-color: transparent;
        padding: 0;
        display: flex;
        align-items: center;
        text-underline-offset: 3px;
        width: fit-content;
        cursor: pointer;
        &:hover {
            font-weight: 700;
        }
    `}
`;
const SouthIcon = styled(southIcon)`
    ${(props) => css`
        color: ${props.theme.colors.darkYellow};
        margin-left: 8px;
        height: 18px !important;
        width: 18px !important;
        &.show-more {
            rotate: 180deg;
        }
    `}
`;
const Branch = styled.div`
    ${(props) => css`
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        margin-bottom: 18px;
        display: flex;
        width: 100%;
        &.no-margin {
            margin-bottom: 0;
        }
        ${props.theme.screen.mobile &&
        css`
            display: block;
        `}
    `}
`;
const Name = styled.div`
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
`;
const Info = styled.div`
    margin-top: 8px;
`;
const BranchWrap = styled.div`
    flex-direction: column;
    width: 100%;
`;
const Divider = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.outlineGrey};
        margin: 0 16px;
        width: 1px;
        ${props.theme.screen.mobile &&
        css`
            height: 1px;
            width: unset;
            margin: 16px 0;
        `}
    `}
`;
const ShowMoreText = styled.div``;

type Props = {
    childCompanies: ChildCompanies[];
};

export const Branches = (props: Props) => {
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore((current) => !current);
    };

    const branchesSlice = (branches: ChildCompanies[]) => {
        if (screenViewPort !== 'mobile' && branches && branches.length > 4 && !showMore) {
            return branches.slice(0, 4);
        }
        if (screenViewPort === 'mobile' && branches && branches.length > 2 && !showMore) {
            return branches.slice(0, 2);
        }
        return branches;
    };

    const showButton = () => {
        if (
            (screenViewPort !== 'mobile' && props.childCompanies.length > 4) ||
            (screenViewPort === 'mobile' && props.childCompanies.length > 2)
        ) {
            return true;
        }
        return false;
    };

    return (
        <BranchesContainer>
            <Header>{t('branch')}</Header>
            <BranchesWrap>
                {props.childCompanies.length > 0 ? (
                    branchesSlice(props.childCompanies).map((branch, index) => {
                        return (
                            <Branch key={index}>
                                <BranchWrap>
                                    <Name>{branch.name}</Name>
                                    <Info>{`${branch.address.street}${branch.address.street ? ',' : ''} ${
                                        branch.address.buildingNum
                                    }${branch.address.roomNum ? '-' : ''}${branch.address.roomNum}${
                                        branch.address.buildingNum.trim() ? ',' : ''
                                    } ${branch.address.city} ${branch.address.zip}${branch.address.zip ? ',' : ''} ${
                                        branch.address.state
                                    }${branch.address.state ? ',' : ''} ${branch.address.country}`}</Info>
                                    <Info>
                                        <NumberFormater number={branch.phone} />
                                    </Info>
                                </BranchWrap>
                                {!Number.isInteger((index + 1) / 4) &&
                                    screenViewPort !== 'mobile' &&
                                    index + 1 !== branchesSlice(props.childCompanies).length && <Divider />}
                                {props.childCompanies.length !== index + 1 &&
                                    screenViewPort === 'mobile' &&
                                    index + 1 !== branchesSlice(props.childCompanies).length && <Divider />}
                            </Branch>
                        );
                    })
                ) : (
                    <Branch className="no-margin">
                        <BranchWrap>
                            <Info>{t('no_information_provided')}</Info>
                        </BranchWrap>
                    </Branch>
                )}
            </BranchesWrap>
            {showButton() && (
                <Button onClick={toggleShowMore}>
                    <ShowMoreText>{!showMore ? t('all_branches') : t('less_branches')}</ShowMoreText>
                    <SouthIcon className={showMore ? 'show-more' : ''} />
                </Button>
            )}
        </BranchesContainer>
    );
};
