import styled, { css } from 'styled-components';
import { t } from 'i18next';

import { TrademarkImage } from '../../../../../types/api';

const BrandsContainer = styled.div`
    ${(props) => css`
        margin-top: 16px;
        padding: 16px;
        border-radius: 8px;
        background-color: ${props.theme.colors.white};
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 13px;
            line-height: 18px;
        `}
    `}
`;
const LogoContainer = styled.div`
    ${(props) => css`
        margin-top: 24px;
        ${props.theme.screen.mobile &&
        css`
            margin-top: 16px;
        `}
    `}
`;
const Img = styled.img`
    ${(props) => css`
        margin-right: 32px;
        max-width: 96px;
        max-height: 35px;
        ${props.theme.screen.mobile &&
        css`
            max-width: 54px;
            max-height: 20px;
        `}
    `}
`;
const Text = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
`;

type Logos = {
    trademarkImages: TrademarkImage[];
};

export const BrandsRepresented = (props: Logos) => {
    return (
        <BrandsContainer>
            <Header>{t('brands_represented')}</Header>
            <LogoContainer>
                {props.trademarkImages.length > 0 ? (
                    props.trademarkImages.map((logo, index) => <Img key={index} src={logo.logoPath} />)
                ) : (
                    <Text>{t('no_information_provided')}</Text>
                )}
            </LogoContainer>
        </BrandsContainer>
    );
};
