import { ReactComponent as StarImg } from '../../assets/images/BusinessListCard/star_icon.svg';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

const ReviewContainer = styled.div`
    display: flex;
    align-items: center;
`;
const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 165px;
`;
const Star = styled(StarImg)`
    ${(props) => css`
        width: 18px;
        height: 19px;
        margin-right: 4px;
        ${props.theme.screen.mobile &&
        css`
            &.bigger-stars {
                width: 14px;
                height: 14px;
            }
        `}
        &.grey {
            filter: grayscale(100);
            opacity: 40%;
        }
    `}
`;
const Rating = styled.div`
    ${(props) => css`
        font-size: 11px;
        font-weight: 600;
        line-height: 14px;
        margin: 0 8px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 10px;
            font-weight: 600;
            line-height: 12px;
        `}
    `}
`;
const FeedbackNumber = styled.div`
    ${(props) => css`
        font-size: 11px;
        font-weight: 600;
        line-height: 14px;
        color: ${props.theme.colors.darkGrey};
        ${props.theme.screen.mobile &&
        css`
            font-size: 10px;
            font-weight: 600;
            line-height: 12px;
        `};
    `}
`;
interface Props {
    noNumber?: boolean;
    starClass?: string;
    rating: number;
    feedbackCount?: number;
}

export const Stars = (props: Props) => {
    const rating: number = props.rating;
    const { t } = useTranslation();

    return (
        <ReviewContainer>
            <Wrapper>
                {Array.from(Array(5), (e, i) => (
                    <Star key={i} className={`${Math.round(rating) <= i && 'grey'} ${props.starClass}`} />
                ))}
                <Rating>
                    {props.rating.toFixed(1)}
                    {t('stars_text')}
                </Rating>
            </Wrapper>
            {props.feedbackCount === undefined ? '' : <FeedbackNumber>({props.feedbackCount})</FeedbackNumber>}
        </ReviewContainer>
    );
};
