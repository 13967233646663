import styled, { css } from 'styled-components';
import { InputItem } from '../../SearchBar/InputItem';
import { useTranslation } from 'react-i18next';

import searchIcon from '@mui/icons-material/Search';
import { useEffect, useRef, useState } from 'react';
import { getArticlesList } from '../../../services/articles.service';
import { IState } from '../../../store';
import { useSelector } from 'react-redux';
import {
    Autocomplete,
    Box,
    Checkbox,
    Chip,
    createFilterOptions,
    createTheme,
    Paper,
    PaperProps,
    TextField,
    TextFieldProps,
    ThemeProvider,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ArticlesPreview, Topic } from '../../../types/api';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

const SearchAndFilterContainer = styled.div``;
const Header = styled.div`
    font-size: 32px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0.01em;
    margin: 16px 0 24px;
`;
const SearchAndSelect = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: space-between;
        ${!props.theme.screen.desktop &&
        css`
            flex-direction: column;
        `}
    `}
`;
const InputWrap = styled.form`
    ${(props) => css`
        width: 353px;
        display: flex;
        ${!props.theme.screen.desktop &&
        css`
            margin-bottom: 16px;
            width: 100%;
        `}
    `}
`;
const SelectWrap = styled.div`
    ${(props) => css`
        width: 291px;
        ${!props.theme.screen.desktop &&
        css`
            width: 100%;
        `}
    `}
`;
const ButtonWrap = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 16px 16px 16px;
`;
const Button = styled.button`
    ${(props) =>
        css`
            &.input {
                width: 48px;
                height: 48px;
                background-color: ${props.theme.colors.black};
                border-radius: 0 8px 8px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: 0.2s ease-in-out;
                &:hover {
                    box-shadow: 0px 3px 0px ${props.theme.colors.outlineAndPlaceholder};
                }
            }
            &.multi-select {
                font-size: 15px;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 0.005em;
                padding: 13px 0;
                border-radius: 8px;
                border: 1px solid ${props.theme.colors.black};
                cursor: pointer;
                transition: 0.2s ease-in-out;
                &:hover {
                    box-shadow: 0px 3px 0px ${props.theme.colors.outlineAndPlaceholder};
                }
                &.multi-select-delete {
                    margin-top: 15px;
                    background-color: ${props.theme.colors.white};
                    margin-bottom: 16px;
                }
                &.multi-select-filter {
                    background-color: ${props.theme.colors.black};
                    color: ${props.theme.colors.white};
                    z-index: 9999999;
                }
            }
        `}
`;
const SearchIcon = styled(searchIcon)`
    color: ${(props) => props.theme.colors.white};
`;

const theme = createTheme({
    components: {
        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: '#F4F4F4',
                    borderRadius: '8px',
                    color: '#666',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontFamily: 'RedHat-Regular',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&:hover, &.Mui-focused': {
                        '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#FFBC22',
                        },
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E4E4E4',
                        borderRadius: '0px',
                    },
                    '&.MuiInputBase-root': {
                        backgroundColor: '#F4F4F4',
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '20px',
                        letterSpacing: '0.005em',
                        padding: '8.5px 4px 7.5px 6px !important',
                        '.MuiChip-root': {
                            margin: '3px 3px 1px',
                        },
                        '.MuiAutocomplete-input': {
                            color: '#666666',
                        },
                        '.MuiChip-label': { maxWidth: '180px !important' },
                    },
                    '&.MuiOutlinedInput-root': {
                        padding: '5.5px 4px 6.5px 6px !important',
                    },
                },
            },
        },
    },
});

type Props = {
    setArticles: (articles: ArticlesPreview[]) => void;
};

export const SearchAndFilter = (props: Props) => {
    const { t } = useTranslation();
    const { searchFilters } = useSelector((state: IState) => state.companies);
    const { gateIsActive } = useSelector((state: IState) => state.global);
    const [searchInput, setSearchInput] = useState('');
    const [selectedTopics, setSelectedTopics] = useState<Topic[]>([]);
    const [clear, setClear] = useState(1);
    const ref = useRef<HTMLDivElement>(null);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const clearFields = () => {
        setClear((prev) => prev + 1);
        setSelectedTopics([]);
    };

    const filterArticleListByQuery = () => {
        const query = searchInput ? `&query=${searchInput}` : '';
        getArticlesList(1, query).then((res) => {
            const articlesWithContent = res.data.filter((article: ArticlesPreview) => article.contentTeaser);
            props.setArticles(articlesWithContent);
        });
    };

    const filterArticlesListByTopics = () => {
        const idString = selectedTopics.length > 0 ? `&topics=${selectedTopics.map((topic) => topic.id)}` : '';
        getArticlesList(1, idString).then((res) => {
            const articlesWithContent = res.data.filter((article: ArticlesPreview) => article.contentTeaser);
            props.setArticles(articlesWithContent);
        });
    };

    // We can remove this useEffect if we don't want to refilter after every selection. But this part of the code is logical, because we have the topics outside of the autocomplete element, thus now, removing the topic doesn't make you got to the autocomplete element, just to press the filter, button.
    useEffect(() => {
        filterArticlesListByTopics();
    }, [selectedTopics]);

    const CustomPaper = (props: PaperProps) => {
        return <Paper {...props} sx={{ border: '1px solid rgba(255, 188, 34, 1)' }} />;
    };

    const ListboxComponent = React.forwardRef<HTMLUListElement, React.HTMLProps<HTMLUListElement>>(
        function ListBoxComponentInner(props, ref) {
            return (
                <>
                    <ul ref={ref} {...props} />
                    <ButtonWrap className="button-wrap">
                        <Button
                            className="multi-select-delete multi-select"
                            onMouseDown={(event) => event.preventDefault()}
                            onClick={clearFields}
                        >
                            {t('remove_filters')}
                        </Button>
                        <Button
                            className="multi-select-filter multi-select"
                            onClick={filterArticlesListByTopics}
                            onMouseDown={(event) => event.preventDefault()}
                        >
                            {t('filter')}
                        </Button>
                    </ButtonWrap>
                </>
            );
        }
    );

    const handleClick = () => {
        const height = gateIsActive ? '270px' : '100px';
        ref.current!.style.scrollMargin = height;
        ref.current?.scrollIntoView({ block: 'start' });
    };

    const onDelete = (name: string) => () => {
        setSelectedTopics((currentValue) => currentValue.filter((v) => v.name !== name));
    };

    return (
        <SearchAndFilterContainer>
            <Header>{t('all_articles')}</Header>
            <SearchAndSelect>
                <InputWrap
                    onSubmit={(e) => {
                        e.preventDefault();
                        filterArticleListByQuery();
                    }}
                >
                    <InputItem id="article_search" placeholder={t('enter_a_search_word')!} onChange={setSearchInput} />
                    <Button type="submit" className="input">
                        <SearchIcon />
                    </Button>
                </InputWrap>
                <SelectWrap ref={ref} className="select-wrap" onClick={handleClick}>
                    <ThemeProvider theme={theme}>
                        <Autocomplete
                            onChange={(e, newValue) => setSelectedTopics(newValue)}
                            filterOptions={createFilterOptions({
                                limit: 10,
                            })}
                            PaperComponent={CustomPaper}
                            limitTags={1}
                            className="auto-complete"
                            multiple
                            renderTags={() => null}
                            value={selectedTopics}
                            key={clear}
                            id="search-check-box-list"
                            options={searchFilters.topics}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            slotProps={{
                                popper: {
                                    sx: {
                                        zIndex: 1,
                                    },
                                },
                            }}
                            renderOption={(props, option, { selected, inputValue }) => {
                                const matches = match(option.name, inputValue, { insideWords: true });
                                const parts = parse(option.name, matches);
                                return (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            sx={{
                                                color: `rgba(102, 102, 102, 1)`,
                                                height: `48px`,
                                                '&.Mui-checked': {
                                                    color: '#170D17',
                                                },
                                            }}
                                        />
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                            ListboxComponent={ListboxComponent}
                            renderInput={(params: TextFieldProps) => (
                                <TextField {...params} placeholder={t('enter_a_search_word') || ''} />
                            )}
                        />
                    </ThemeProvider>
                </SelectWrap>
            </SearchAndSelect>
            <ThemeProvider theme={theme}>
                <Box
                    mt={3}
                    sx={{
                        '& > :not(:last-child)': { mr: 1 },
                        '& > .MuiChip-root': { mb: 1 },
                        '& > *': { mr: 1 },
                    }}
                >
                    {selectedTopics.map((v) => (
                        <Chip key={v.name} label={v.name} onDelete={onDelete(v.name)} deleteIcon={<CloseIcon />} />
                    ))}
                </Box>
            </ThemeProvider>
        </SearchAndFilterContainer>
    );
};
