import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DevicesIcon from '@mui/icons-material/Devices';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactoryIcon from '@mui/icons-material/Factory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import LanguageIcon from '@mui/icons-material/Language';
import HouseIcon from '@mui/icons-material/House';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import RestaurantIcon from '@mui/icons-material/Restaurant';

import styled from 'styled-components';

const IconWrapper = styled.div<CustomStyles>`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    .MuiSvgIcon-root {
        width: 100%;
        height: 100%;
        color: ${(props) => props.color || props.theme.colors.black};
    }
`;

interface Props {
    id: number;
    customStyles?: CustomStyles;
}

interface CustomStyles {
    color?: string;
    width?: number;
    height?: number;
}

const icons: { [key: number]: JSX.Element } = {
    19: <ElectricBoltIcon />,
    20: <AccountBalanceIcon />,
    21: <DevicesIcon />,
    22: <MedicalServicesIcon />,
    23: <LocalShippingIcon />,
    24: <SportsEsportsIcon />,
    25: <FactoryIcon />,
    26: <ShoppingCartIcon />,
    27: <NewspaperIcon />,
    28: <LanguageIcon />,
    29: <HouseIcon />,
    30: <AutoStoriesIcon />,
    31: <SettingsSuggestIcon />,
    32: <DirectionsCarIcon />,
    33: <LocalPoliceIcon />,
    34: <RestaurantIcon />,
};

export const IconById = ({ id, customStyles }: Props) => {
    return <IconWrapper {...customStyles}>{icons[id]}</IconWrapper>;
};
