import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { getIndividualCompany } from '../../services/company.service';
import { setBreadcrumb, setHeaderSearchOpen } from '../../store/global.slice';
import { useSelector } from 'react-redux';
import { IState } from '../../store';
import { ArticlesPreview, CompanyData } from '../../types/api';
import { Head } from './components/Head/Head';
import { InformationAndEmployees } from './InformationAndEmployees';
import { ArticleCard } from '../Articles/ArticlesAndSidebar/ArticleCard';
import { getArticlesList } from '../../services/articles.service';
import { useTranslation } from 'react-i18next';

const BusinessCardContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
`;
const BusinessCard = styled.div`
    width: 100%;
    max-width: 1254px;
    display: flex;
    flex-direction: column;
    margin: 0 16px 32px;
`;
const Line = styled.div`
    ${(props) => css`
        height: 1px;
        width: 500%;
        align-self: center;
        background-color: ${props.theme.colors.outlineGrey};
    `}
`;
const SimilarArticles = styled.div`
    ${(props) => css`
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        background-color: ${props.theme.colors.greyBg};
        width: 100%;
    `}
`;
const ArticleWrap = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
`;
const ArticlesHeader = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.01em;
    margin: 48px 0px 16px;
`;
const Wrap = styled.div`
    width: 1254px;
    margin: 0 16px 48px;
`;
const Article = styled(ArticleCard)`
    width: 1254px;
    margin: 0 16px;
`;

export const BusinessCardInner = () => {
    const [companyData, setCompanyData] = useState<CompanyData>();
    const [articles, setArticles] = useState<ArticlesPreview[]>([]);
    const { t } = useTranslation();

    const { screenViewPort } = useSelector((state: IState) => state.global);

    const urlParams = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setHeaderSearchOpen(false));
        if (urlParams.companyId) {
            getIndividualCompany(parseInt(urlParams.companyId)).then((response: CompanyData) => {
                setCompanyData(response);
                dispatch(
                    setBreadcrumb({
                        key: 'companyName',
                        value: response.name,
                    })
                );
                const companyTopicsIds = response.topics.map((topic) => topic.id);
                if (companyTopicsIds.length > 0) {
                    const companyTopicsIdsString = companyTopicsIds?.join(',');
                    const idString = `&topics=${companyTopicsIdsString}`;

                    getArticlesList(1, idString).then((res) => {
                        const articlesWithContent = res.data.filter(
                            (article: ArticlesPreview) => article.contentTeaser
                        );
                        const companiesArticles: ArticlesPreview[] = [];
                        articlesWithContent.forEach((article) => {
                            if (article.companyId.toString() === urlParams.companyId) {
                                companiesArticles.push(article);
                            }
                        });
                        if (companiesArticles.length > 0) {
                            setArticles(companiesArticles);
                        } else {
                            setArticles(articlesWithContent);
                        }
                    });
                }
            });
        }

        return () => {
            dispatch(setHeaderSearchOpen(true));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {companyData && (
                <BusinessCardContainer>
                    <BusinessCard>
                        <Head data={companyData} />
                        {screenViewPort !== 'mobile' && <Line />}
                        <InformationAndEmployees data={companyData} />
                    </BusinessCard>
                    {screenViewPort === 'desktop' && articles.length > 0 && (
                        <SimilarArticles>
                            <Wrap>
                                <ArticlesHeader>{t('articles')}</ArticlesHeader>
                                <ArticleWrap>
                                    {articles && articles.map((article) => <Article key={article.id} {...article} />)}
                                </ArticleWrap>
                            </Wrap>
                        </SimilarArticles>
                    )}
                </BusinessCardContainer>
            )}
        </>
    );
};
