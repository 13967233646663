import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MUIPagination, { PaginationRenderItemParams } from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { PaginationItem } from '@mui/material';

import { IState } from '../../store';
import { ArticlesPreview } from '../../types/api';

import { ReactComponent as Arrow } from '../../assets/images/Slider/arrow.svg';
import styled, { css } from 'styled-components';
import { getArticlesList } from '../../services/articles.service';
import { resetPagination } from '../../store/global.slice';

interface Props {
    setArticles: (articles: ArticlesPreview[]) => void;
}

const StyledStack = styled(Stack)`
    width: 100%;
    ${(props) => css`
        .pagination-item {
            background-color: ${props.theme.colors.greyBg} !important;
            margin: 0;
            padding: 0;
            &.selected {
                font-weight: 700;
            }
        }
    `}
`;

const ArrowPrevious = styled(Arrow)`
    transform: rotate(180deg);
`;

export const Pagination = (props: Props) => {
    const dispatch = useDispatch();
    const { pagination } = useSelector((state: IState) => state.global);
    const pageCount = Math.ceil(pagination.articles.total / pagination.articles.perPage);

    const handlePageChange = (event: React.ChangeEvent<unknown> | null, value: number) => {
        const query = pagination.articles.firstPageUrl.split('?')[1];
        const queryObj = query.split('&').reduce((acc: any, curr: string) => {
            const [key, value] = curr.split('=');
            acc[key] = decodeURIComponent(value);
            return acc;
        }, {});
        let filter = '';
        Object.keys(queryObj).forEach((key) => {
            if (key !== 'page') {
                filter += `&${key}=${queryObj[key]}`;
            }
        });
        getArticlesList(value, filter).then((res) => {
            window.scrollTo(0, 0);
            props.setArticles(res.data);
        });
    };

    useEffect(() => {
        return () => {
            dispatch(resetPagination());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledStack sx={{ alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '30px' }}>
            <MUIPagination
                siblingCount={0}
                boundaryCount={1}
                count={pageCount || 1}
                page={pagination.articles.currentPage}
                shape="rounded"
                variant="text"
                onChange={handlePageChange}
                renderItem={(item: PaginationRenderItemParams) => (
                    <PaginationItem
                        {...item}
                        classes={{ root: 'pagination-item', selected: 'selected' }}
                        slots={{ previous: ArrowPrevious, next: Arrow }}
                    />
                )}
            />
        </StyledStack>
    );
};
