import { createSlice } from '@reduxjs/toolkit';
import { initialLanguageState, ILanguageState } from './types';

const languageSlice = createSlice({
    name: 'language',
    initialState: initialLanguageState,
    reducers: {
        changeLanguage: (state: ILanguageState, action) => {
            state.selectedLanguage = action.payload;
        },
    },
});

export const { changeLanguage } = languageSlice.actions;

export default languageSlice.reducer;
