import styled, { css } from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../store';
import classNames from 'classnames';
import { InputItem } from './InputItem';
import { getSearchCompaniesList, getSearchFilters } from '../../services/company.service';
import { setSearchFilters } from '../../store/companies/companies.slice';

import backGroundImg from '../../assets/images/SearchBar/search_bar_background.svg';
import closeIcon from '@mui/icons-material/Close';
import searchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import { setHeaderSearchOnScreen } from '../../store/global.slice';
import useOnScreen from '../../hooks/IsElementOnScreen';
import { AutocompleteSelect } from './AutocompleteSelect';
import { QueryParams } from '../../types/api';
import { useNavigate } from 'react-router-dom';
import { AutocompleteInput } from './AutocompleteInput';
import { Box, CircularProgress } from '@mui/material';

const BackGround = styled.div`
    ${(props) => css`
        background-image: url(${backGroundImg});
        background-attachment: fixed;
        background-position: 0 -100px;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        padding-top: 88px;
        height: auto;
        margin-bottom: 0px;
        ${!props.theme.screen.desktop &&
        css`
            margin-bottom: 16px;
            padding-top: 68px;
        `}
    `}
`;
const SearchBarContainer = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.white};
        width: 1040px;
        height: fit-content;
        margin: 32px 24px;
        padding: 24px;
        ${props.theme.screen.mobile &&
        css`
            padding: 16px;
        `};
    `}
`;
const SearchForm = styled.form``;
const SearchFieldset = styled.fieldset`
    border: none;
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0.01em;
        color: ${props.theme.colors.black};
        margin-bottom: 16px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
        `}
    `}
`;
const SearchWrap = styled.div`
    ${(props) => css`
        display: flex;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
        `}
    `}
`;
const Button = styled.button`
    ${(props) => css`
        background-color: ${props.theme.colors.black};
        border-radius: 0px 8px 8px 0px;
        border: none;
        min-width: 48px;
        font-weight: 400;
        height: 48px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        &:hover {
            box-shadow: 0px 3px 0px ${props.theme.colors.outlineAndPlaceholder};
            font-weight: 700;
        }
        &.filter-button {
            background-color: ${props.theme.colors.yellow};
            border-radius: 8px 0px 0px 8px;
            transition: 0.2s ease-in-out;
            &:hover {
                background: ${props.theme.colors.darkerYellow};
                box-shadow: 0px 3px 0px ${props.theme.colors.darkYellow};
                font-weight: 700;
            }
        }
        &.additional-search-button {
            background-image: none;
            width: 100%;
            border-radius: 8px;
            color: ${props.theme.colors.white};
            margin-top: 16px;
            height: 44px;
            font-size: 15px;
            font-weight: 600;
            line-height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            &.reset {
                background-color: ${props.theme.colors.white};
                color: ${props.theme.colors.black};
                border: 1px solid ${props.theme.colors.black};
            }
            &.undo {
                background-color: transparent;
                color: ${props.theme.colors.black};
                height: fit-content;
            }
        }
    `}
`;
const SelectsInputsBottom = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.greyBg};
        margin-top: 16px;
        border-radius: 8px;
        padding: 16px;
        ${props.theme.screen.mobile &&
        css`
            margin-top: 8px;
        `}
    `}
`;
const OnlySelectsInputsWrap = styled.div`
    ${(props) => css`
        border-radius: 8px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        ${props.theme.screen.mobile &&
        css`
            grid-template-columns: 1fr;
        `}
    `}
`;
const InputWrap = styled.div`
    ${(props) => css`
        display: flex;
        width: 66%;
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
            margin-bottom: 16px;
        `}
    `}
`;
const SelectWrap = styled.div`
    display: flex;
    &.mobileScreen {
        display: none;
    }
    &.inputAdded {
        display: flex;
    }
`;
const MobileModule = styled.div`
    ${(props) => css`
        &.hide {
            display: none;
        }
        ${props.theme.screen.mobile &&
        css`
            position: fixed;
            top: 0;
            left: 0;
            /* We remove the height and width of the container paddings */
            width: calc(100% - 32px);
            height: calc(100% - 41px);
            margin-top: 0;
            border-radius: 0;
            /* Mobile burger menu z-index is 1301 */
            z-index: 1302;
            overflow: auto;
            &.mobile {
                background-color: ${props.theme.colors.black};
                padding: 25px 16px 16px 16px;
            }
        `}
    `}
`;
const MobileFilterHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;
const MobileHeader = styled.div`
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 25px;
`;
const MobileFilterText = styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
`;
const MobileFilterWrap = styled.div`
    color: ${(props) => props.theme.colors.white};
    height: auto;
`;
const ShowOnInput = styled.div`
    ${(props) => css`
        width: 34%;
        ${props.theme.screen.mobile &&
        css`
            width: 100%;
        `}
    `}
`;
const MobileInputButtons = styled.div``;
const SearchInput = styled.div`
    width: 100%;
`;
const Wrapper = styled.div`
    display: flex;
    width: 100%;
`;

const CloseIcon = styled(closeIcon)`
    &.smaller {
        height: 18px;
        width: 18px;
        background-repeat: no-repeat;
        margin-right: 8px;
    }
`;
const SearchIcon = styled(searchIcon)`
    ${(props) => css`
        &.white-search {
            color: ${props.theme.colors.white};
            &.smaller {
                margin-right: 8px;
                height: 18px;
                width: 18px;
            }
            &.mobile {
                position: absolute;
                color: ${props.theme.colors.outlineAndPlaceholder};
                margin: 16px 0 0 8px;
            }
        }
    `}
`;

const initialFilters = {
    name: '',
    manager: '',
    legal_type: '',
    company_code: '',
    vat_code: '',
    days: [],
    head: '',
    topic: 0,
    category: 0,
    address: '',
    city: 0,
    query: '',
};

interface DisabledFilters {
    advancedTopic: boolean;
}

export const SearchBar = () => {
    const dispatch = useDispatch();

    const { searchFilters } = useSelector((state: IState) => state.companies);
    const { screenViewPort, loading } = useSelector((state: IState) => state.global);
    const { categoriesList } = useSelector((state: IState) => state.categories);

    const [formKey, setFormKey] = useState(0);
    const [filters, setFilters] = useState<QueryParams>({ ...initialFilters });
    const [disabledFilters, setDisabledFilters] = useState<DisabledFilters>({
        advancedTopic: false,
    });
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [mobileInput, setMobileInput] = useState(false);

    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen(ref);

    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSet = (boolean: boolean) => {
        setMobileInput(boolean);
    };

    useEffect(() => {
        dispatch(setHeaderSearchOnScreen(isVisible));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    const setFilterValue = (filter: { [key: string]: string | number }) => {
        setFilters((prev) => ({ ...prev, ...filter }));
    };

    const searchForCompanies = () => {
        if (loading) {
            return;
        }
        getSearchCompaniesList(1, filters).then((res) => {
            setShowAdvanced(false);
            resetFilters();
            const state = { companies: res.data, filters: filters };
            if (typeof filters.topic === 'number') {
                const category = categoriesList.find((category) =>
                    category.topics.find((topic) => topic.id === filters.topic)
                );

                if (category) {
                    navigate(`imoniu-sarasas/${category.id}/${filters.topic}`, { state });
                    return;
                }
            }
            navigate('imoniu-sarasas', { state });
        });
    };

    const resetFilters = () => {
        setFormKey((prev) => prev + 1);
        setFilters({ ...initialFilters });
    };

    const handleQueryInput = (value: string | number) => {
        if (typeof value === 'number') {
            setFilterValue({ topic: value, query: '' });
            setDisabledFilters({ advancedTopic: true });
            return;
        }
        setDisabledFilters({ advancedTopic: false });
        setFilterValue({ query: value });
    };

    useEffect(() => {
        getSearchFilters().then((res) => {
            dispatch(setSearchFilters(res));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BackGround ref={ref}>
            <SearchBarContainer>
                <SearchForm
                    onSubmit={(e) => {
                        e.preventDefault();
                        searchForCompanies();
                    }}
                >
                    <Header>{t('place_for_your_business')}</Header>
                    <SearchFieldset disabled={loading}>
                        <SearchWrap>
                            <InputWrap>
                                <Button
                                    type="button"
                                    className="filter-button"
                                    onClick={() => setShowAdvanced((prev) => !prev)}
                                >
                                    <TuneIcon />
                                </Button>
                                <Wrapper>
                                    <SearchInput onClick={() => handleSet(true)}>
                                        <AutocompleteInput
                                            fieldKey={`query-${formKey}`}
                                            id="query_input"
                                            onChange={(value) => handleQueryInput(value)}
                                            options={searchFilters.topics}
                                            name={t('enter_a_search_word')!}
                                            optionLabel="name"
                                            allowFreeText={true}
                                            disabled={loading}
                                        />
                                    </SearchInput>
                                </Wrapper>
                            </InputWrap>
                            <ShowOnInput>
                                <SelectWrap
                                    className={classNames({
                                        mobileScreen: screenViewPort === 'mobile',
                                        inputAdded: mobileInput,
                                    })}
                                >
                                    <AutocompleteSelect
                                        fieldKey={`city-${formKey}`}
                                        class="main-select"
                                        name={t('choose_city')}
                                        options={searchFilters?.city}
                                        onChange={(e) => setFilterValue({ city: e?.id || 0 })}
                                        optionLabel="city"
                                        matchFrom="start"
                                        id="choose_city"
                                        disabled={loading}
                                    />
                                    {screenViewPort !== 'mobile' && (
                                        <Box sx={{ position: 'relative' }}>
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    searchForCompanies();
                                                }}
                                            >
                                                {!loading && <SearchIcon className="white-search" />}
                                            </Button>
                                            {loading && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: 'white',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-14px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    )}
                                </SelectWrap>
                                {screenViewPort === 'mobile' && mobileInput && (
                                    <MobileInputButtons>
                                        <Box sx={{ position: 'relative' }}>
                                            <Button
                                                className="additional-search-button"
                                                onClick={() => {
                                                    searchForCompanies();
                                                }}
                                            >
                                                {!loading && (
                                                    <>
                                                        <SearchIcon className="white-search smaller" />
                                                        {t('search')}
                                                    </>
                                                )}
                                            </Button>
                                            {loading && (
                                                <CircularProgress
                                                    size={24}
                                                    sx={{
                                                        color: 'white',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                        </Box>
                                        <Button
                                            className="additional-search-button undo"
                                            onClick={() => {
                                                handleSet(false);
                                            }}
                                        >
                                            <CloseIcon className="smaller" />
                                            {t('cancel')}
                                        </Button>
                                    </MobileInputButtons>
                                )}
                            </ShowOnInput>
                        </SearchWrap>
                        <MobileModule
                            className={classNames({
                                mobile: screenViewPort === 'mobile',
                                hide: !showAdvanced,
                            })}
                        >
                            {screenViewPort === 'mobile' && (
                                <MobileFilterWrap>
                                    <MobileFilterHeaderContainer>
                                        <MobileHeader>{t('extended_search')}</MobileHeader>
                                        <CloseIcon type="button" onClick={() => setShowAdvanced((prev) => !prev)} />
                                    </MobileFilterHeaderContainer>
                                    <MobileFilterText>{t('company_search')}</MobileFilterText>
                                </MobileFilterWrap>
                            )}
                            <SelectsInputsBottom>
                                <OnlySelectsInputsWrap>
                                    <InputItem
                                        id="search_address_input"
                                        placeholder={t('search_address')!}
                                        value={filters.address}
                                        onChange={(value) => setFilterValue({ address: value })}
                                    />
                                    {/* TODO: implement worker_number filter when backend adds support for it */}
                                    {/* <SelectItem name={t('worker_number')} /> */}
                                    <AutocompleteSelect
                                        fieldKey={`area-${formKey}`}
                                        name={t('area_of_activity')}
                                        options={searchFilters?.topics}
                                        onChange={(e) => setFilterValue({ topic: e?.id || 0 })}
                                        optionLabel="name"
                                        id="area_of_activity"
                                        disabled={loading || disabledFilters.advancedTopic}
                                    />
                                    {/* TODO: Find out which filter specifically corresponds to 'brand' */}
                                    {/* <SelectItem name={t('brand')} /> */}
                                    <InputItem
                                        id="company_code_input"
                                        placeholder={t('company_code')!}
                                        value={filters.company_code}
                                        onChange={(value) => setFilterValue({ company_code: value || '' })}
                                    />
                                    <InputItem
                                        id="vat_code_input"
                                        placeholder={t('vat_code')!}
                                        value={filters.vat_code}
                                        onChange={(value) => setFilterValue({ vat_code: value || '' })}
                                    />
                                    <AutocompleteSelect
                                        fieldKey={`working-${formKey}`}
                                        name={t('working_hours')}
                                        options={searchFilters?.workingTime}
                                        onChange={(e) => setFilterValue({ days: e || [] })}
                                        multiple={true}
                                        optionLabel="name"
                                        id="choose_working_hours"
                                        disabled={loading}
                                    />
                                    <InputItem
                                        id="manager_input"
                                        placeholder={t('manager')!}
                                        value={filters.manager}
                                        onChange={(value) => setFilterValue({ manager: value || '' })}
                                    />
                                    <AutocompleteSelect
                                        fieldKey={`business-${formKey}`}
                                        name={t('business_type')}
                                        customTranslationKey="legal_types"
                                        options={Object.values(searchFilters?.legalType).filter((item) => item)}
                                        onChange={(e) => setFilterValue({ legal_type: e || '' })}
                                        id="choose_business_type"
                                        disabled={loading}
                                    />
                                </OnlySelectsInputsWrap>
                                {screenViewPort === 'mobile' && (
                                    <Button
                                        className="additional-search-button reset"
                                        onClick={() => {
                                            resetFilters();
                                        }}
                                    >
                                        {t('clear_all')}
                                    </Button>
                                )}
                                <Box sx={{ position: 'relative' }}>
                                    <Button
                                        className="additional-search-button"
                                        onClick={() => {
                                            searchForCompanies();
                                        }}
                                    >
                                        {!loading && t('search')}
                                    </Button>
                                    {loading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: 'white',
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </SelectsInputsBottom>
                        </MobileModule>
                    </SearchFieldset>
                </SearchForm>
            </SearchBarContainer>
        </BackGround>
    );
};
