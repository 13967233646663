import { Stars } from '../../Stars/Stars';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';
import { CompanyListItem } from '../../../types/api';

import styled, { css } from 'styled-components';
import phone from '../../../assets/images/BusinessListCard/phone.svg';
import letter from '../../../assets/images/BusinessListCard/letter.svg';
import globe from '../../../assets/images/BusinessListCard/globe.svg';
import pin from '../../../assets/images/BusinessListCard/pin.svg';
import lext from '../../../assets/images/BusinessListCard/lext.png';
import { NumberFormater } from '../../NumberFormater/NumberFormater';

const Text = styled.a`
    ${(props) => css`
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        text-decoration: none;
        color: ${props.theme.colors.black};
        &.last {
            max-height: 67px;
            overflow: hidden;
            ${!props.theme.screen.desktop &&
            css`
                max-height: 53px;
            `}
        }
        &.no-margin {
            margin: 0;
            display: flex;
            align-items: center;
        }
        &.contacts {
            height: 36px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-bottom: 12px;
            ${!props.theme.screen.mobile &&
            css`
                align-items: center;
                display: flex;
                width: 49%;
            `}
            ${props.theme.screen.mobile &&
            css`
                flex-direction: column;
                margin-bottom: 16px;
            `}
        }
        &.first {
            margin-right: 2%;
            ${props.theme.screen.mobile &&
            css`
                order: 1;
                margin-right: 0;
            `}
        }
        &.second {
            ${props.theme.screen.mobile &&
            css`
                order: 2;
            `}
        }
        &.third {
            margin-right: 2%;
            ${props.theme.screen.mobile &&
            css`
                order: 3;
                margin-right: 0;
            `}
        }
        &.fourth {
            ${props.theme.screen.mobile &&
            css`
                order: 4;
            `}
        }
    `}
`;
const TextWithContactInfo = styled.div`
    ${(props) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        ${props.theme.screen.mobile &&
        css`
            flex-wrap: wrap-reverse;
            gap: 0;
        `}
    `}
`;
const ContactInfo = styled.div`
    ${(props) => css`
        padding: 0 16px;
        display: flex;
        flex-wrap: wrap;
        ${props.theme.screen.mobile &&
        css`
            display: flex;
            justify-content: space-between;
        `}
    `}
`;
const Icon = styled.img`
    ${(props) => css`
        padding-right: 8px;
        ${props.theme.screen.mobile &&
        css`
            padding: 8px;
            height: 18px;
            border: 1px solid ${props.theme.colors.outlineGrey};
            border-radius: 8px;
        `}
    `}
`;

const TextContainer = styled.div`
    ${(props) => css`
        display: flex;
        background-color: ${props.theme.colors.outlineGrey};
        padding: 16px 16px;
    `}
`;

const Logo = styled.img`
    ${(props) => css`
        object-fit: contain;
        height: 64px;
        max-width: 112px;
        margin-right: 16px;
        ${props.theme.screen.mobile &&
        css`
            height: 48px;
            max-width: 84px;
        `}
    `}
`;
const StarsContainer = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px 0 16px;
        ${props.theme.screen.mobile &&
        css`
            padding: 8px 16px 0 16px;
        `}
    `}
`;
const Lext = styled.img`
    ${(props) => css`
        width: 42px;
        height: 14px;
        ${props.theme.screen.mobile &&
        css`
            padding: 8px;
            border: 1px solid ${props.theme.colors.outlineGrey};
            border-radius: 8px;
        `}
    `}
`;
const LabelContainer = styled.div`
    height: 32px;
`;
const Divider = styled.div`
    ${(props) => css`
        height: 1px;
        width: calc(100% - 32px);
        background-color: ${props.theme.colors.outlineGrey};
        margin: 12px 16px;
        ${props.theme.screen.mobile &&
        css`
            margin: 8px 16px 16px 16px;
        `}
    `}
`;
interface Props {
    data: CompanyListItem;
}

export const CardMid = (props: Props) => {
    const { screenViewPort } = useSelector((state: IState) => state.global);

    const desktopTabletScreen = (information: string) => {
        return screenViewPort !== 'mobile' && <NumberFormater number={information} />;
    };

    const webLinkFormater = (props: Props) => {
        const array = props.data.website.split('/');
        return array[2];
    };

    const stopPropogation = (e: any) => {
        e.stopPropagation();
    };

    const showInMapClicked = (lat: number, long: number) => {
        if (lat && long) {
            window.open('https://maps.google.com?q=' + lat + ',' + long);
        }
    };

    return (
        <TextWithContactInfo>
            {props.data.description != null && (
                <TextContainer>
                    {props.data.logo && <Logo src={`${props.data.logo.logoPath}`} />}
                    <Text className="last">{props.data.description}</Text>
                </TextContainer>
            )}
            <StarsContainer>
                <Stars
                    rating={Number(props.data.rating.toFixed(1))}
                    feedbackCount={props.data.feedbackCount}
                    starClass={'bigger-stars'}
                />
                <LabelContainer>{props.data.lextIntegration && <Lext src={lext} />}</LabelContainer>
            </StarsContainer>
            <Divider />
            <ContactInfo>
                {props.data.address && (
                    <Text
                        className="contacts first"
                        onClick={(e) => {
                            stopPropogation(e);
                            showInMapClicked(props.data.address.latitude, props.data.address.longitude);
                        }}
                    >
                        <Icon src={pin} />
                        {desktopTabletScreen(
                            `${props.data.address.street}${props.data.address.street ? ',' : ''} ${
                                props.data.address.buildingNum
                            }${props.data.address.roomNum ? '-' : ''}${props.data.address.roomNum}${
                                props.data.address.buildingNum.trim() ? ',' : ''
                            } ${props.data.address.city} ${props.data.address.zip}${
                                props.data.address.zip ? ',' : ''
                            } ${props.data.address.state}${props.data.address.state ? ',' : ''} ${
                                props.data.address.country
                            }`
                        )}
                    </Text>
                )}
                {props.data.phone && (
                    <Text
                        className="contacts second"
                        onClick={(e) => stopPropogation(e)}
                        href={`tel:${props.data.phone}`}
                    >
                        <Icon src={phone} />
                        {desktopTabletScreen(props.data.phone)}
                    </Text>
                )}
                {props.data.email && (
                    <Text
                        onClick={(e) => stopPropogation(e)}
                        className="contacts third"
                        href={`mailto:${props.data.email}`}
                    >
                        <Icon src={letter} />
                        {desktopTabletScreen(props.data.email)}
                    </Text>
                )}
                {props.data.website !== null && (
                    <Text
                        onClick={(e) => stopPropogation(e)}
                        className="contacts fourth"
                        target="_blank"
                        href={props.data.website}
                    >
                        <Icon src={globe} />
                        {desktopTabletScreen(webLinkFormater(props))}
                    </Text>
                )}
            </ContactInfo>
        </TextWithContactInfo>
    );
};
