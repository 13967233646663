import { t } from 'i18next';
import styled, { css } from 'styled-components';
import { CompanyResponse } from '../../../../../types/api';
import { useState } from 'react';
import classNames from 'classnames';
import arrowForwardIcon from '@mui/icons-material/ArrowForward';
import { RelatedTopics } from './RelatedTopics';

const DescriptionContainer = styled.div`
    margin-top: 16px;
    border-radius: 8px;
`;
const DescriptionWrap = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.white};
        padding: 16px;
        border-radius: 8px 8px 0 0;
        padding: 16px;
        ${props.theme.screen.mobile &&
        css`
            border-radius: 8px;
        `}
    `}
`;
const Name = styled.div`
    ${(props) => css`
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 13px;
            line-height: 18px;
        `}
    `}
`;
const Text = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
`;

const GreyContainer = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.greyBg};
        display: flex;
        flex-direction: column;
        padding: 16px;
        border-radius: 8px;
    `}
`;
const Html = styled.div``;

const Button = styled.div`
    ${(props) => css`
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: ${props.theme.colors.darkYellow};
        text-decoration: underline;
        text-transform: uppercase;
        border: none;
        background-color: transparent;
        padding: 0;
        display: flex;
        align-items: center;
        text-underline-offset: 3px;
        width: fit-content;
        margin-top: 18px;
        cursor: pointer;
        &:hover {
            font-weight: 700;
        }
        ${!props.theme.screen.desktop &&
        css`
            margin-top: 24px;
        `}
    `}
`;

const Arrow = styled(arrowForwardIcon)`
    height: 18px !important;
    width: 18px !important;
    margin-left: 8px;
    &.up {
        rotate: 270deg;
    }
`;

const LongDescription = styled.div`
    transition: max-height 0.7s ease;
    overflow: hidden;
    max-height: 0;
    &.slide {
        max-height: 2000px;
    }
`;

const SearchWords = styled.div``;
const Word = styled.div``;

export const Description = (props: CompanyResponse) => {
    const [descriptionOpen, setDescriptionOpen] = useState(false);

    return (
        <DescriptionContainer>
            <DescriptionWrap>
                {props.data.description && (
                    <GreyContainer>
                        <Name>{t('description')}</Name>
                        <Text>{props.data.description}</Text>
                        <LongDescription className={classNames({ slide: descriptionOpen })}>
                            <Html dangerouslySetInnerHTML={{ __html: props.data.descriptionLong }} />
                        </LongDescription>
                        {props.data.descriptionLong && (
                            <Button
                                onClick={() => {
                                    setDescriptionOpen((prev) => !prev);
                                }}
                            >
                                {descriptionOpen ? <>{t('hide_description')}</> : <>{t('full_description')}</>}
                                <Arrow className={classNames({ up: descriptionOpen })} />
                            </Button>
                        )}
                    </GreyContainer>
                )}
                {props.data.topics?.length > 0 && <RelatedTopics topics={props.data.topics} />}
                {props.data.keywords.length > 0 && (
                    <GreyContainer>
                        <Name>{t('search_words')}:</Name>
                        <SearchWords>
                            {props.data.keywords.map((word) => (
                                <Word>{word.keywords}</Word>
                            ))}
                        </SearchWords>
                    </GreyContainer>
                )}
            </DescriptionWrap>
        </DescriptionContainer>
    );
};
