import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Footer } from '../../components/Footer/Footer';
import { HorizontalBanner } from '../../components/HorizontalBanner/HorizontalBanner';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import { IState } from '../../store';
import { AdGate } from '../../components/AdGate/AdGate';
import { ROUTE_NAMES } from '../../constants/routes';
import { setGateIsActive, setBannersByTopic, setBannersByKeyword } from '../../store/global.slice';
import { useEffect } from 'react';
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import styled from 'styled-components';

const LoadingModal = styled.div`
    background: rgba(255, 255, 255, 0.4);
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 2;
    position: fixed;
`;

export const MainPageLayout = () => {
    const {
        screenViewPort,
        headerSearchOpen,
        gateIsActive,
        bannersList,
        loading,
        bannersFilteredByTopic,
        bannersFilteredByKeyword,
    } = useSelector((state: IState) => state.global);
    const location = useLocation();
    const companyListUrlChecker = location.pathname.split('/');
    const dispatch = useDispatch();
    const urlParams = useParams();
    const { state } = useLocation();

    useEffect(() => {
        if (
            (bannersFilteredByTopic.W001.length > 0 && bannersList.W001[0].image.includes('.png')) ||
            (bannersFilteredByKeyword.W003.length > 0 && bannersList.W003[0].image.includes('.png')) ||
            (bannersList.W005.length > 0 && bannersList.W005[0].image.includes('.png'))
        ) {
            dispatch(setGateIsActive(true));
            return;
        }

        dispatch(setGateIsActive(false));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersFilteredByTopic.W001, bannersFilteredByKeyword.W003, bannersList.W005]);

    useEffect(() => {
        dispatch(setBannersByTopic(urlParams?.topicId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersList, urlParams?.topicId]);

    useEffect(() => {
        dispatch(setBannersByKeyword(state?.filters?.query));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.filters?.query]);

    return (
        <>
            <Box>
                {(headerSearchOpen || screenViewPort !== 'desktop') && <SearchBar />}
                {(location.pathname === ROUTE_NAMES.HOME || companyListUrlChecker[1] === 'imoniu-sarasas') &&
                    companyListUrlChecker[2] !== 'imones-kortele' &&
                    gateIsActive &&
                    companyListUrlChecker.length < 5 &&
                    screenViewPort === 'desktop' && <AdGate />}
                {screenViewPort === 'desktop' && <HorizontalBanner />}
                {location.pathname !== ROUTE_NAMES.HOME && <Breadcrumbs />}
                <Outlet />
                <Footer />
                {loading && (
                    <LoadingModal>
                        <CircularProgress
                            size={40}
                            sx={{
                                color: 'black',
                                position: 'fixed',
                                top: '50%',
                                left: '50%',
                            }}
                        />
                    </LoadingModal>
                )}
            </Box>
        </>
    );
};
