import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Topic } from '../../../types/api';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';
import { useNavigate } from 'react-router-dom';

const ReservationContainer = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.outlineGrey};
        padding: 12px 24px;
    `}
`;
const BonusesContainer = styled.div`
    ${(props) => css`
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        ${props.theme.screen.mobile &&
        css`
            display: flex;
        `}
    `}
`;
const Bonus = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        margin-right: 16px;
        color: ${props.theme.colors.darkGrey};
        border: 1px solid ${props.theme.colors.outlineGrey};
        &:hover {
            text-decoration: underline;
        }
    `}
`;
const Categories = styled.div`
    ${(props) => css`
        color: ${props.theme.colors.black};
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        margin-right: 16px;
    `}
`;

interface Props {
    topics: Topic[];
}

export const CardBottom = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { categoriesList } = useSelector((state: IState) => state.categories);

    const navigateToTopic = (topicId: number, e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        const category = categoriesList.find((category) => category.topics.find((topic) => topic.id === topicId));
        if (category) {
            navigate(`/imoniu-sarasas/${category.id}/${topicId}`);
        }
    };

    return (
        <>
            <ReservationContainer>
                <BonusesContainer>
                    <Categories>{t('topics')}:</Categories>
                    {props.topics?.map((topic) => (
                        <Bonus onClick={(e) => navigateToTopic(topic.id, e)} key={topic.id}>
                            {topic.name}
                        </Bonus>
                    ))}
                </BonusesContainer>
            </ReservationContainer>
        </>
    );
};
