import i18n from '../i18n/config';

import TopicBreadcrumb from '../components/Breadcrumbs/DynamicMatchers/TopicBreadcrumb';
import CompanyBreadcrumb from '../components/Breadcrumbs/DynamicMatchers/CompanyBreadcrumb';
import ArticleBreadcrumb from '../components/Breadcrumbs/DynamicMatchers/ArticleBreadcrumb';
import CategoryBreadcrumb from '../components/Breadcrumbs/DynamicMatchers/CategoryBreadcrumb';

export const ROUTE_NAMES = {
    HOME: '/',
    BUSINESS_LIST: '/imoniu-sarasas',
    BUSINESS_LIST_CATEGORY: ':categoryId',
    BUSINESS_LIST_TOPIC: ':topicId',
    PAGE_NOT_FOUND: '*',
    CATEGORIES_AND_TOPICS: '/visos-kategorijos',
    COMPANY_CARD: 'imones-kortele/:companyId',
    EU_PROJECTS: 'informacija/es-projektai',
    ARTICLE_PAGE: 'straipsniai',
    ARTICLE_INNER: ':cardId',
};

export const routes = [
    {
        path: ROUTE_NAMES.HOME,
        breadcrumb: i18n.t('main_page'),
        disableGate: false,
    },
    {
        path: ROUTE_NAMES.EU_PROJECTS,
        breadcrumb: i18n.t('eu_projects'),
    },
    {
        path: ROUTE_NAMES.PAGE_NOT_FOUND,
        breadcrumb: null,
    },
    {
        path: ROUTE_NAMES.ARTICLE_PAGE,
        breadcrumb: i18n.t('articles'),
        children: [
            {
                path: ROUTE_NAMES.ARTICLE_INNER,
                breadcrumb: ArticleBreadcrumb,
                children: [
                    {
                        path: ROUTE_NAMES.COMPANY_CARD,
                        breadcrumb: CompanyBreadcrumb,
                    },
                ],
            },
        ],
    },
    {
        path: ROUTE_NAMES.BUSINESS_LIST,
        breadcrumb: i18n.t('business_list'),
        children: [
            {
                path: ROUTE_NAMES.BUSINESS_LIST_CATEGORY,
                breadcrumb: CategoryBreadcrumb,
                children: [
                    {
                        path: ROUTE_NAMES.BUSINESS_LIST_TOPIC,
                        breadcrumb: TopicBreadcrumb,
                        children: [
                            {
                                path: ROUTE_NAMES.COMPANY_CARD,
                                breadcrumb: CompanyBreadcrumb,
                            },
                        ],
                    },
                    {
                        path: ROUTE_NAMES.COMPANY_CARD,
                        breadcrumb: CompanyBreadcrumb,
                    },
                ],
            },
            {
                path: ROUTE_NAMES.COMPANY_CARD,
                breadcrumb: CompanyBreadcrumb,
            },
        ],
    },
    {
        path: ROUTE_NAMES.CATEGORIES_AND_TOPICS,
        breadcrumb: i18n.t('all_categories'),
        children: [
            {
                path: ROUTE_NAMES.COMPANY_CARD,
                breadcrumb: CompanyBreadcrumb,
            },
        ],
    },
];
