import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IconById } from '../Categories/IconById';
import { IState } from '../../store';
import { Topic } from '../../types/api';

import SouthIcon from '@mui/icons-material/South';

import styled, { css } from 'styled-components';
import classnames from 'classnames';
import { ICategory } from '../../store/categories/types';
import { Link } from 'react-router-dom';
import { theme } from '../../assets/styles/theme';

const Content = styled.div`
    ${(props) =>
        css`
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 1023px;
            background-color: ${props.theme.colors.greyBg};
            border: 1px solid ${props.theme.colors.outlineGrey};
        `}
`;

const HeaderWrapper = styled.div`
    ${(props) =>
        css`
            display: flex;
            background-color: ${props.theme.colors.black};
            height: 56px;
            align-items: center;
            padding-left: 16px;
            gap: 16px;
        `}
`;

const Heading = styled.div`
    font-size: 18px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.white};
`;

const Icon = styled(IconById)`
    color: ${(props) => props.theme.colors.white};
`;

const TopicsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 16px;
    ${(props) =>
        props.theme.screen.tablet &&
        css`
            grid-template-columns: repeat(3, 1fr);
        `}
    ${(props) =>
        props.theme.screen.mobile &&
        css`
            grid-template-columns: repeat(2, 1fr);
        `}
`;

const TopicItem = styled(Link)`
    border-radius: 8px;
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.white};
    padding: 16px;
    text-decoration: none;
    &:hover {
        transition: box-shadow 0.2s ease-in-out;
        cursor: pointer;
        box-shadow: 0px 3px 0px ${(props) => props.theme.colors.darkGrey};
        font-weight: 700;
    }
`;

const ShowMoreButton = styled.div`
    text-decoration: underline;
    color: ${(props) => props.theme.colors.darkYellow};
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 0 16px 16px;
`;

const ShowMoreText = styled.div`
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    text-transform: uppercase;
`;

const ArrowIcon = styled(SouthIcon)`
    transition: transform 0.2s ease-in-out;
    &.up {
        transform: rotate(-180deg);
    }
`;

interface Props {
    category: ICategory;
    showMore: boolean;
    setShowMoreValue: (value: boolean, categoryId: number) => void;
}

export const CategoryTopics = (props: Props) => {
    const { screenViewPort } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();

    const topicsSlice = (topics: Topic[]) => {
        if (screenViewPort === 'mobile' && topics && topics.length > 6 && !props.showMore) {
            return topics.slice(0, 6);
        }
        return topics;
    };

    return (
        <Content>
            <HeaderWrapper>
                <Icon id={props.category.id} customStyles={{ width: 24, height: 24, color: theme.colors.white }} />
                <Heading>{props.category.name}</Heading>
            </HeaderWrapper>
            <TopicsWrapper>
                {props.category.topics &&
                    topicsSlice(props.category.topics).map((topic: Topic) => {
                        return (
                            <React.Fragment key={topic.id}>
                                <TopicItem to={`/imoniu-sarasas/${props.category.id}/${topic.id}`}>
                                    {topic.name}
                                </TopicItem>
                            </React.Fragment>
                        );
                    })}
            </TopicsWrapper>
            {props.category.topics && props.category.topics.length > 6 && screenViewPort === 'mobile' && (
                <ShowMoreButton onClick={() => props.setShowMoreValue(!props.showMore, props.category.id)}>
                    <ShowMoreText>{!props.showMore ? t('more_categories') : t('less_categories')}</ShowMoreText>
                    <ArrowIcon
                        className={classnames({
                            up: props.showMore,
                        })}
                    />
                </ShowMoreButton>
            )}
        </Content>
    );
};
