import { createSlice } from '@reduxjs/toolkit';
import { initialCompaniesState, ICompaniesState } from './types';

const companiesSlice = createSlice({
    name: 'companies',
    initialState: initialCompaniesState,
    reducers: {
        setCompaniesList: (state: ICompaniesState, action) => {
            state.companiesList = action.payload;
        },
        setSearchFilters: (state: ICompaniesState, action) => {
            state.searchFilters = action.payload;
        },
        setPagination: (state: ICompaniesState, action) => {
            state.pagination = action.payload;
        },
        resetPagination: (state: ICompaniesState) => {
            state.pagination = initialCompaniesState.pagination;
        },
        setRecommendedCompanies: (state, action) => {
            state.recommendedCompanies = action.payload;
        },
    },
});

export const { setCompaniesList, setSearchFilters, setPagination, resetPagination, setRecommendedCompanies } =
    companiesSlice.actions;

export default companiesSlice.reducer;
