import { t } from 'i18next';
import styled, { css } from 'styled-components';
import { CompanyResponse } from '../../../../../types/api';

const ManagerContainer = styled.div`
    ${(props) => css`
        background-color: ${props.theme.colors.white};
        border-radius: 8px;
        padding: 16px;
        margin-top: 16px;
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 13px;
            line-height: 18px;
        `}
    `}
`;
const Text = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    margin-top: 16px;
    &.smaller-margin-top {
        margin-top: 8px;
    }
`;

export const Manager = (props: CompanyResponse) => {
    return (
        <ManagerContainer>
            <Header>{t('manager')}</Header>
            {props.data.manager && (
                <>
                    <Text className="smaller-margin-top">{props.data.manager.name}</Text>
                </>
            )}
            {props.data.manager?.description && (
                <Text className="smaller-margin-top">{props.data.manager.description}</Text>
            )}
        </ManagerContainer>
    );
};
