import styled, { css } from 'styled-components';
import { Slider } from '../Slider/Slider';
import background from '../../assets/images/Slider/background.svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { IState } from '../../store';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { CompanyListItem } from '../../types/api';
const CenteringWrap = styled.div`
    display: flex;
    justify-content: center;
`;
const SliderWrap = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        padding: 48px 0;
        background-image: url(${background});
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &.gate-not-active {
            width: 100%;
        }
        ${props.theme.screen.mobile &&
        css`
            padding: 0;
            background-image: none;
        `}
    `}
`;
const SliderContainer = styled.div`
    padding: 24px;
    max-width: 1302px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.white};
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
        text-align: center;
        margin-bottom: 8px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 24px;
            line-height: 29px;
        `}
    `}
`;
const Subtitles = styled.div`
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    margin-bottom: 16px;
`;

interface WeeklyCompanyLogo {
    logoPath: string;
    url: string;
}

export const WeeklyCompanyList = () => {
    const { gateIsActive } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();
    const [companiesOfTheWeek, setCompaniesOfTheWeek] = useState<WeeklyCompanyLogo[]>();
    const { recommendedCompanies } = useSelector((state: IState) => state.companies);

    useEffect(() => {
        const arrayOfWeeklyCompaniesLogos: WeeklyCompanyLogo[] = [];
        recommendedCompanies.forEach((company: CompanyListItem) => {
            if (company.logo) {
                arrayOfWeeklyCompaniesLogos.push({ logoPath: company.logo.logoPath, url: company.website });
            }
        });
        setCompaniesOfTheWeek(arrayOfWeeklyCompaniesLogos);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recommendedCompanies]);

    return (
        <CenteringWrap>
            {companiesOfTheWeek && companiesOfTheWeek.length > 0 && (
                <SliderWrap className={classNames({ 'gate-not-active': !gateIsActive })}>
                    <SliderContainer>
                        <Header>{t('weekly_businesses')}</Header>
                        <Subtitles>{t('weekly_businesses_small')}</Subtitles>
                        <Slider
                            logoUrls={companiesOfTheWeek}
                            amountPerScreen={{ desktop: 6, tablet: 4, mobile: 2 }}
                            weeklyCompanies={true}
                        />
                    </SliderContainer>
                </SliderWrap>
            )}
        </CenteringWrap>
    );
};
