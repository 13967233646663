import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Languages } from '../constants/languages';
import localStorage from '../commons/localStorage';
import { SELECTED_LANGUAGE_KEY } from '../constants/languages';

const userSelectedLanguage = localStorage.get(SELECTED_LANGUAGE_KEY);

i18n.use(initReactI18next).init({
    fallbackLng: Languages.LT,
    lng: userSelectedLanguage ? Object.keys(userSelectedLanguage)[0].toLocaleLowerCase() : Languages.LT,
    resources: {
        en: {
            translations: require('./locales/en/translations.json'),
        },
        lt: {
            translations: require('./locales/lt/translations.json'),
        },
        ru: {
            translations: require('./locales/ru/translations.json'),
        },
    },
    ns: ['translations'],
    defaultNS: 'translations',
});

i18n.languages = [Languages.LT, Languages.EN];

export default i18n;
