import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { InputItem } from '../InputItem';
import { AutocompleteSelect } from '../AutocompleteSelect';

import { getSearchCompaniesList } from '../../../services/company.service';
import { Topic } from '../../../types/api';
import { IState } from '../../../store';

import CloseIcon from '@mui/icons-material/Close';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import SliderItem from '../SliderItem';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: 'Albert-Regular';
    margin-bottom: 57px;
    &.mobile {
        background: ${(props) => props.theme.colors.white};
        position: fixed;
        top: 0;
        z-index: 1304;
        left: 0;
        height: calc(100% - 32px);
        width: calc(100% - 32px);
        padding: 16px;
        overflow: scroll;
    }
    ${(props) => css`
        ${props.theme.screen.mobile &&
        css`
            &:not(.mobile) {
                margin-bottom: 0;
            }
            ${Button} {
                width: 100% !important;
            }
        `}
    `}
`;

const Heading = styled.div`
    ${(props) => css`
        color: ${props.theme.colors.black};
        font-size: 18px;
        line-height: 120%;
    `}
`;

const FilterContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px 30px;
    margin-top: 8px;
    ${(props) => css`
        ${props.theme.screen.mobile &&
        css`
            grid-template-columns: 1fr;
        `}
    `}
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
    ${(props) => css`
        ${props.theme.screen.mobile &&
        css`
            margin-top: 20px;
            &:not(.heading) {
                align-items: flex-end;
                height: 100%;
            }
        `}
    `}
    &.heading {
        height: 70px;
        margin-top: 16px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    ${(props) => css`
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            gap: 16px;
            width: 100%;
        `}
    `}
`;

const Button = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${props.theme.colors.black};
        background-color: ${props.theme.colors.yellow};
        border-radius: 10px;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        margin-top: 8px;
        padding: 12px 0;
        width: 165px;
        cursor: pointer;
        &.clear {
            border: 1px solid ${props.theme.colors.black};
            background-color: ${props.theme.colors.white};
            color: ${props.theme.colors.black};
            width: 135px;
        }
        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
        &:hover {
            font-weight: 700;
        }
        ${props.theme.screen.mobile &&
        css`
            padding: 15px 0;
            width: 157px !important;
            margin-top: 0;
            &:first-child {
                order: 1;
            }
        `}
    `}
`;

const Results = styled.div`
    font-size: 16px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.black};
`;

const MobileHeading = styled.div`
    font-size: 18px;
    line-height: 120%;
    color: ${(props) => props.theme.colors.black};
    font-weight: 700;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.colors.outlineLight};
    margin: 24px 0;
`;

type FilterValues = {
    query: string | null;
    address: string | null;
    employeeCount: string | null;
    topic: Topic | null;
    brand: string | null;
    companyCode: string | null;
    companyVatCode: string | null;
    director: string | null;
    companyType: string | null;
    workingHours: string | null;
    rating: string | null;
    incomeFrom: number;
    incomeTo: number;
    profitFrom: number;
    profitTo: number;
    turnoverFrom: number;
    turnoverTo: number;
};

const employeeRange = [
    {
        value: '0-4',
        label: '0-4',
    },
    {
        value: '5-9',
        label: '5-9',
    },
    {
        value: '10-19',
        label: '10-19',
    },
    {
        value: '20-49',
        label: '20-49',
    },
    {
        value: '50-99',
        label: '50-99',
    },
    {
        value: '100-249',
        label: '100-249',
    },
    {
        value: '250-499',
        label: '250-499',
    },
    {
        value: '500-999',
        label: '500-999',
    },
    {
        value: '1000-4999',
        label: '1000+',
    },
];

const companyTypes = [
    {
        value: 'UAB',
        label: 'UAB',
    },
    {
        value: 'AB',
        label: 'AB',
    },
];

const workingHours = [
    {
        value: '08:00:00-17:00:00',
        label: '8-17',
    },
    {
        value: '08:00:00-19:00:00',
        label: '8-19',
    },
];

const initialFilterValues = {
    query: '',
    address: '',
    employeeCount: null,
    topic: null,
    brand: '',
    companyCode: '',
    companyVatCode: '',
    director: '',
    companyType: '',
    workingHours: null,
    rating: null,
    incomeFrom: 0,
    incomeTo: 10000000,
    profitFrom: 0,
    profitTo: 10000000,
    turnoverFrom: 0,
    turnoverTo: 10000000,
};

type Props = {
    query: string;
};

export const AdditionalFilters = (props: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlParams = useParams();
    const { t } = useTranslation();
    const { searchFilters, pagination } = useSelector((state: IState) => state.companies);
    const { loading, screenViewPort } = useSelector((state: IState) => state.global);

    const [showOnMobile, setShowOnMobile] = useState(false);
    const [filterValues, setFilterValues] = useState<FilterValues>(initialFilterValues);
    const [formKey, setFormKey] = useState(0);

    const onFilterChange = (value: string | number, key: keyof FilterValues) => {
        setFilterValues((current) => ({ ...current, [key]: value }));
    };

    const resetFilters = () => {
        setFormKey((current) => current + 1);
        setFilterValues({ ...initialFilterValues });
    };

    const searchForCompanies = () => {
        if (loading) {
            return;
        }
        getSearchCompaniesList(1, buildFilters()).then((res) => {
            const state = { companies: res.data };
            navigate(location.pathname, { state });
        });

        if (showOnMobile) {
            setShowOnMobile(false);
        }
    };

    const buildFilters = () => {
        const [employees_from, employees_to] = filterValues.employeeCount?.split('-') || [];
        const [working_time_from, working_time_to] = filterValues.workingHours?.split('-') || [];
        let additionalFilter = { key: '', value: '' };

        if (!filterValues.topic?.id) {
            if (urlParams.categoryId && !urlParams.topicId) {
                additionalFilter = { key: 'category', value: urlParams.categoryId };
            }
            if (urlParams.categoryId && urlParams.topicId && !urlParams.headId) {
                additionalFilter = { key: 'topic', value: urlParams.topicId };
            }
            if (urlParams.headId) {
                additionalFilter = { key: 'head', value: urlParams.headId };
            }
        }

        return {
            query: props.query || '',
            address: filterValues.address || '',
            employees_from: employees_from || '',
            employees_to: employees_to || '',
            topic: filterValues.topic?.id || 0,
            brand: filterValues.brand || '',
            compnay_code: filterValues.companyCode || '',
            vat_code: filterValues.companyVatCode || '',
            manager: filterValues.director || '',
            legal_type: filterValues.companyType || '',
            working_time_from: working_time_from || '',
            working_time_to: working_time_to || '',
            rating: filterValues.rating || '',
            income_from: filterValues.incomeFrom || 0,
            income_to: filterValues.incomeTo || 1000000,
            profit_from: filterValues.profitFrom || 0,
            profit_to: filterValues.profitTo || 1000000,
            turnover_from: filterValues.turnoverFrom || 0,
            turnover_to: filterValues.turnoverTo || 1000000,
            [additionalFilter.key]: additionalFilter.value,
        };
    };

    useEffect(() => {
        if (screenViewPort !== 'mobile') {
            setShowOnMobile(false);
        }
    }, [screenViewPort]);

    useEffect(() => {
        return () => {
            resetFilters();
        };
    }, []);

    const handleChangeIncome = (event: Event, newValue: number | number[], activeThumb: number) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            onFilterChange(newValue[0], 'incomeFrom');
        } else {
            onFilterChange(newValue[1], 'incomeTo');
        }
    };

    const handleChangeProfit = (event: Event, newValue: number | number[], activeThumb: number) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            onFilterChange(newValue[0], 'profitFrom');
        } else {
            onFilterChange(newValue[1], 'profitTo');
        }
    };

    const handleChangeTurnover = (event: Event, newValue: number | number[], activeThumb: number) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            onFilterChange(newValue[0], 'turnoverFrom');
        } else {
            onFilterChange(newValue[1], 'turnoverTo');
        }
    };

    return (
        <>
            {screenViewPort === 'mobile' && (
                <Button className="clear" onClick={() => setShowOnMobile(true)}>
                    {t('filter_companies')}
                </Button>
            )}
            <Container
                className={classNames({
                    mobile: showOnMobile,
                })}
            >
                {screenViewPort === 'mobile' && showOnMobile && (
                    <>
                        <Wrapper className="heading">
                            <MobileHeading>{t('filter_companies')}</MobileHeading>
                            <CloseIcon onClick={() => setShowOnMobile(false)} />
                        </Wrapper>
                        <Divider />
                    </>
                )}
                {(screenViewPort !== 'mobile' || (screenViewPort === 'mobile' && showOnMobile)) && (
                    <>
                        <Heading>{t('filter_companies')}:</Heading>
                        <FilterContainer>
                            <InputItem
                                id="filter_address"
                                placeholder={t('filters.address')}
                                value={filterValues.address}
                                onChange={(e) => onFilterChange(e, 'address')}
                            />
                            <AutocompleteSelect
                                fieldKey={`employeeCount${formKey}`}
                                name={t('filters.employee_amount')}
                                options={employeeRange}
                                onChange={(e) =>
                                    setFilterValues((current) => ({ ...current, employeeCount: e?.value }))
                                }
                                id="employee_count"
                                optionLabel="label"
                                disabled={loading}
                            />
                            <AutocompleteSelect
                                fieldKey={`topic${formKey}`}
                                name={t('filters.area_of_activity')}
                                options={searchFilters?.topics}
                                onChange={(e) => setFilterValues((current) => ({ ...current, topic: e }))}
                                optionLabel="name"
                                id="specialization"
                                disabled={loading}
                            />
                            <InputItem
                                id="filter_brand"
                                placeholder={t('filters.brand_represented')}
                                value={filterValues.brand}
                                onChange={(e) => onFilterChange(e, 'brand')}
                            />
                            <InputItem
                                id="filter_compnay_code"
                                placeholder={t('filters.company_code')}
                                value={filterValues.companyCode}
                                onChange={(e) => onFilterChange(e, 'companyCode')}
                            />
                            <InputItem
                                id="filter_compnay_vat_code"
                                placeholder={t('filters.company_vat_code')}
                                value={filterValues.companyVatCode}
                                onChange={(e) => onFilterChange(e, 'companyVatCode')}
                            />
                            <AutocompleteSelect
                                fieldKey={`workingHours${formKey}`}
                                name={t('filters.working_hours')}
                                options={workingHours}
                                onChange={(e) => setFilterValues((current) => ({ ...current, workingHours: e?.value }))}
                                id="working_time"
                                optionLabel="label"
                                disabled={loading}
                            />
                            <InputItem
                                id="filter_director"
                                placeholder={t('filters.manager')}
                                value={filterValues.director}
                                onChange={(e) => onFilterChange(e, 'director')}
                            />
                            <AutocompleteSelect
                                fieldKey={`companyType${formKey}`}
                                name={t('filters.legal_type')}
                                options={companyTypes}
                                onChange={(e) => setFilterValues((current) => ({ ...current, companyType: e?.value }))}
                                id="legal_type"
                                optionLabel="label"
                                disabled={loading}
                            />
                        </FilterContainer>
                        <Wrapper>
                            <ButtonContainer>
                                <Button onClick={() => searchForCompanies()}>{t('filter')}</Button>
                                <Button className="clear" onClick={() => resetFilters()}>
                                    {t('clear_all')}
                                </Button>
                            </ButtonContainer>
                            {screenViewPort !== 'mobile' && (
                                <Results>
                                    {t('filters.found_results')} {pagination.total}
                                </Results>
                            )}
                        </Wrapper>
                    </>
                )}
            </Container>
        </>
    );
};
