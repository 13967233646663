import { useState } from 'react';
import { FormControl, TextField, Autocomplete, createFilterOptions } from '@mui/material';
import styled, { css } from 'styled-components';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const Form = styled(FormControl)`
    ${(props) => css`
        width: 100%;
        height: 46px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        cursor: pointer;
        .root {
            ${(props) => css`
                #form-input-label {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: ${props.theme.colors.outlineAndPlaceholder};
                }
                &.category-search .inputRoot {
                    border-radius: 8px 0 0 8px;
                    border: none;
                }
            `}
            .inputRoot {
                border-radius: 0;
                padding-top: 20px;
                background-color: ${props.theme.colors.greyBg};
                border: solid 1px ${props.theme.colors.outlineGrey};
                border-style: none none none solid;
                height: 48px;
                &:hover,
                &:focus-within {
                    background-color: ${props.theme.colors.greyBg};
                    border: 1px solid ${props.theme.colors.outlineAndPlaceholder};
                }
                ${props.theme.screen.mobile &&
                css`
                    border-radius: 0 8px 8px 0;
                    border: solid 1px ${props.theme.colors.greyBg};
                `}
                .input {
                    font-size: 14px;
                    padding: 2px 4px 3px;
                }
            }
        }
    `}
`;

const StyledAutocomplete = styled(Autocomplete).attrs({
    classes: {
        root: 'root',
        inputRoot: 'inputRoot',
        input: 'input',
    },
})``;

interface Props {
    onChange: (value: any) => void;
    name: string;
    options: any[];
    id: string;
    optionLabel?: string;
    fieldKey?: string;
    allowFreeText?: boolean;
    showPopupIcon?: boolean;
    disabled?: boolean;
    class?: string;
}

export const AutocompleteInput = (props: Props) => {
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const handleChange = (value: any) => {
        props.onChange(value?.id ? value.id : value);
    };

    const optionLabel = (option: any) => {
        if (props.optionLabel && option[props.optionLabel]) {
            return option[props.optionLabel];
        }
        return option;
    };

    return (
        <>
            <Form
                variant="filled"
                sx={{
                    '& :after': { display: 'none' },
                    '& :before': { display: 'none' },
                    '& .MuiChip-root': {
                        width: 'auto',
                        height: '20px',
                        margin: '0 3px 8px 3px',
                        '& .MuiSvgIcon-root': {
                            fontSize: '15px',
                        },
                    },
                }}
            >
                <StyledAutocomplete
                    className={props.class}
                    disabled={props.disabled ? true : false}
                    autoComplete={false}
                    key={props.fieldKey}
                    freeSolo={props.allowFreeText || false}
                    forcePopupIcon={props.showPopupIcon || false}
                    disablePortal
                    fullWidth
                    options={props.options}
                    onChange={(e, value) => {
                        handleChange(value);
                    }}
                    filterOptions={createFilterOptions({
                        limit: 10,
                    })}
                    getOptionLabel={(option: any) => optionLabel(option)}
                    renderOption={(props, option, { inputValue }) => {
                        const matches = match(optionLabel(option), inputValue, { insideWords: true });
                        const parts = parse(optionLabel(option), matches);
                        return (
                            <li {...props}>
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={props.name}
                            value={inputValue}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                id: 'form-input-label',
                            }}
                            variant="filled"
                            size="small"
                        />
                    )}
                    onInputChange={(e, value) => {
                        setInputValue(value);
                        handleChange(value);
                        if (!value) {
                            setOpen(false);
                        }
                    }}
                    open={open}
                    onOpen={() => {
                        if (inputValue) {
                            setOpen(true);
                        }
                    }}
                    onClose={() => {
                        setOpen(false);
                    }}
                />
            </Form>
        </>
    );
};
