import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ROUTE_NAMES } from '../../../constants/routes';
import { useSelector } from 'react-redux';
import { IState } from '../../../store';

import eastIcon from '@mui/icons-material/East';

const Container = styled.div`
    display: flex;
    align-items: center;
    margin: 48px 0 32px 0;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            margin: 16px 0;
            justify-content: space-between;
        `}
`;
const Text = styled.div`
    flex: 1;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            font-size: 24px;
            flex: none;
        `}
`;
const Button = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.black};
    padding: 13px 24px;
    color: ${(props) => props.theme.colors.white};
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.005em;
    text-decoration: none;
    &:hover {
        transition: box-shadow 0.2s ease-in-out;
        box-shadow: 0px 3px 0px ${(props) => props.theme.colors.outlineAndPlaceholder};
        cursor: pointer;
        font-weight: 700;
    }
`;
const Arrow = styled(eastIcon)`
    width: 14px;
    margin-left: 4px;
`;

export const HeaderWithCategories = () => {
    const { t } = useTranslation();
    const { screenViewPort } = useSelector((state: IState) => state.global);
    return (
        <Container>
            <Text>{t('articles')}</Text>
            <Button to={ROUTE_NAMES.ARTICLE_PAGE}>
                {t('all_articles')}
                {screenViewPort !== 'mobile' && <Arrow fontSize="small" />}
            </Button>
        </Container>
    );
};
// Is unused, but Giedrė said this is still in consideration
