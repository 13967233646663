import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CategoryCard } from './CategoryCard';
import { AdCard } from '../AdCard/AdCard';
import { Link } from 'react-router-dom';

import { getCategoriesList } from '../../services/categories.service';
import { setCategoriesList } from '../../store/categories/categories.slice';
import { IState } from '../../store';
import { ROUTE_NAMES } from '../../constants/routes';

import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import eastIcon from '@mui/icons-material/East';

const CompanyTopicsWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1254px;
    margin: 0 16px;
    margin-bottom: 48px;
    width: 100%;
`;
const HeaderWrap = styled.div`
    display: flex;
    align-items: center;
    margin: 16px 0 48px;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            margin: 16px 0;
            justify-content: space-between;
        `}
`;
const Header = styled.h2`
    flex: 1;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            font-size: 24px;
            flex: none;
        `}
`;
const Button = styled(Link)`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: ${props.theme.colors.black};
        text-align: center;
        padding: 13px 24px;
        color: ${props.theme.colors.white};
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        text-decoration: none;
        &:hover {
            transition: box-shadow 0.2s ease-in-out;
            box-shadow: 0px 3px 0px ${props.theme.colors.outlineAndPlaceholder};
            cursor: pointer;
            font-weight: 700;
        }
    `}
`;
const Arrow = styled(eastIcon)`
    margin-left: 4px;
`;

const ContainerWrap = styled.div`
    display: flex;
    justify-content: space-between;
`;
const CardsWrap = styled.div`
    display: grid;
    width: 74.5%;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px 2.5%;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            gap: 16px 1.7%;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        `}
`;
const AdsWrapper = styled.div`
    ${(props) => css`
        display: grid;
        grid-template-columns: 100%;
        gap: 16px;
        padding: 16px;
        border: 1px solid ${props.theme.colors.outlineGrey};
        ${!props.theme.screen.desktop &&
        css`
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 282px;
        `}
    `}
`;
const RowWrapper = styled.div`
    display: flex;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            width: 49.15%;
            justify-content: center;
        `}
    ${(props) =>
        props.theme.screen.tablet &&
        css`
            display: grid;
            grid-template-columns: 1fr;
            width: 32.2%;
        `}
`;

const ExpandedRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const SideBar = styled.div`
    ${(props) => css`
        width: 23%;
        ${!props.theme.screen.desktop &&
        css`
            display: flex;
            justify-content: center;
            width: 100%;
        `}
    `}
`;

export const Categories = () => {
    const dispatch = useDispatch();
    const { categoriesList } = useSelector((state: IState) => state.categories);
    const { screenViewPort, bannersList } = useSelector((state: IState) => state.global);
    const { t } = useTranslation();

    useEffect(() => {
        if (categoriesList?.length > 0) {
            return;
        }
        getCategoriesList().then((resp) => {
            dispatch(setCategoriesList(resp));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <CompanyTopicsWrapper>
            <HeaderWrap>
                <Header>{screenViewPort === 'desktop' ? t('company_categories') : t('categories')}</Header>
                <Button to={ROUTE_NAMES.CATEGORIES_AND_TOPICS}>
                    {t('all_categories')}
                    {screenViewPort !== 'mobile' && <Arrow fontSize="small" />}
                </Button>
            </HeaderWrap>
            <ContainerWrap>
                <CardsWrap>
                    {categoriesList?.length > 0 &&
                        categoriesList.map((category, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <RowWrapper>
                                        <CategoryCard category={category} />
                                    </RowWrapper>
                                    {Number.isInteger((index + 1) / 4) &&
                                        bannersList.WBM[(index + 1) / 4 - 1] &&
                                        screenViewPort === 'mobile' && (
                                            <ExpandedRow>
                                                <AdsWrapper>
                                                    <AdCard
                                                        adUrl={bannersList.WBM[(index + 1) / 4 - 1].image}
                                                        adHref={bannersList.WBM[(index + 1) / 4 - 1].url}
                                                    />
                                                </AdsWrapper>
                                            </ExpandedRow>
                                        )}
                                    {Number.isInteger((index + 1) / 3) &&
                                        bannersList.WBM[(index + 1) / 3 - 1] &&
                                        screenViewPort === 'tablet' && (
                                            <ExpandedRow>
                                                <AdsWrapper>
                                                    <AdCard
                                                        adUrl={bannersList.WBM[(index + 1) / 3 - 1].image}
                                                        adHref={bannersList.WBM[(index + 1) / 3 - 1].url}
                                                    />
                                                </AdsWrapper>
                                            </ExpandedRow>
                                        )}
                                </React.Fragment>
                            );
                        })}
                </CardsWrap>
                {screenViewPort === 'desktop' && (
                    <SideBar>
                        <AdsWrapper>
                            {bannersList.WBM.length > 0 &&
                                bannersList.WBM.slice(0, 3).map((banner, index) => {
                                    return <AdCard key={index} adUrl={banner.image} adHref={banner.url} />;
                                })}
                        </AdsWrapper>
                    </SideBar>
                )}
            </ContainerWrap>
        </CompanyTopicsWrapper>
    );
};
