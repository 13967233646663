import { connect } from 'react-redux';
import { IState } from '../../../store';
import { ICategory } from '../../../store/categories/types';

const PureCategoryBreadcrumb = (category: ICategory) => {
    return <span>{category?.name}</span>;
};

const mapStateToProps = (state: IState, props: any) => {
    const { categoryId } = props.match.params;
    const category = state.categories.categoriesList.find((category) => category.id === parseInt(categoryId));

    return (
        category || {
            name: '...',
        }
    );
};

export default connect(mapStateToProps)(PureCategoryBreadcrumb);
