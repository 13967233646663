import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../assets/images/Header/logo.svg';
import { ReactComponent as LogoSmaller } from '../../assets/images/Header/logo_smaller.svg';
import styled, { css } from 'styled-components';
import { BurgerMenu } from './BurgerMenu';
import { IState } from '../../store';
import { ROUTE_NAMES } from '../../constants/routes';
import { Link, NavLink } from 'react-router-dom';
import searchIcon from '@mui/icons-material/Search';
import { setHeaderSearchOpen } from '../../store/global.slice';
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher';

const HeaderCenter = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        border-bottom: 1px solid ${props.theme.colors.outlineGrey};
    `}
`;
const HeaderWrapper = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: space-between;
        color: ${props.theme.colors.black};
        height: 72px;
        display: flex;
        width: 1254px;
        padding: 0px 16px;
        font-size: 15px;
        line-height: 18px;
        font-weight: 600;
        ${!props.theme.screen.desktop &&
        css`
            height: 52px;
        `}
    `}
`;
const ItemWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    &.language-switcher {
        width: 40px;
        height: 100%;
    }
`;
const Buttons = styled.div`
    display: flex;
    align-items: center;
`;
const HeaderLogo = styled(Logo)`
    margin-right: 64px;
`;
const HeaderLogoSmaller = styled(LogoSmaller)``;
const Divider = styled.div`
    height: 24px;
    width: 1px;
    margin: 0 16px;
    background-color: ${(props) => props.theme.colors.outlineGrey};
`;
const BlackLine = styled.div`
    height: 16px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.black};
`;
const Wrap = styled.div`
    display: flex;
    align-items: center;
`;
const StyledLink = styled(NavLink)`
    text-decoration: none;
    color: ${(props) => props.theme.colors.black};
    &:hover {
        text-decoration: underline;
        text-underline-offset: 4px;
    }
    &.active {
        font-weight: 700;
    }
`;
const FullHeader = styled.div`
    position: fixed;
    width: 100%;
    background-color: ${(props) => props.theme.colors.white};
    z-index: 1301;
`;
const SearchIcon = styled(searchIcon)`
    cursor: pointer;
`;
const LoginButton = styled.a`
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.005em;
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
        text-underline-offset: 4px;
    }
`;

const loginUrl = process.env.REACT_APP_LOGIN_REDIRECT || 'https://klientams.1588.lt/';

export const Header = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { screenViewPort, headerSearchOpen, headerSearchOnScreen } = useSelector((state: IState) => state.global);

    const handleToggleChange = (): void => {
        window.scrollTo(0, 0);
        if (!headerSearchOpen) {
            dispatch(setHeaderSearchOpen(true));
        }
    };

    return (
        <FullHeader>
            <BlackLine />
            <HeaderCenter>
                <HeaderWrapper>
                    <ItemWrapper>
                        <Link to={ROUTE_NAMES.HOME}>
                            {screenViewPort === 'desktop' ? <HeaderLogo /> : <HeaderLogoSmaller />}
                        </Link>
                        {screenViewPort === 'desktop' && (
                            <Buttons className="header-buttons">
                                <StyledLink to={ROUTE_NAMES.HOME}>{t('main_page')}</StyledLink>
                                <Divider />
                                <StyledLink to={ROUTE_NAMES.ARTICLE_PAGE}>{t('articles')}</StyledLink>
                                <Divider />
                                <StyledLink to={ROUTE_NAMES.EU_PROJECTS}>{t('eu_projects')}</StyledLink>
                            </Buttons>
                        )}
                    </ItemWrapper>
                    {screenViewPort === 'desktop' && (
                        <Wrap>
                            {(!headerSearchOpen || !headerSearchOnScreen) && (
                                <>
                                    <ItemWrapper>
                                        <SearchIcon onClick={handleToggleChange} />
                                    </ItemWrapper>
                                    <Divider />
                                </>
                            )}
                            <ItemWrapper className="language-switcher">
                                <LanguageSwitcher />
                            </ItemWrapper>
                            <Divider />
                            <ItemWrapper>
                                <LoginButton href={loginUrl} target="_blank">
                                    {t('login')}
                                </LoginButton>
                            </ItemWrapper>
                        </Wrap>
                    )}
                    {screenViewPort !== 'desktop' && <BurgerMenu />}
                </HeaderWrapper>
            </HeaderCenter>
        </FullHeader>
    );
};
