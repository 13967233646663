import axios, { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import localStorage from '../../commons/localStorage';
import { SELECTED_LANGUAGE_KEY } from '../../constants/languages';

const http = axios.create();
const userLanguage = localStorage.get(SELECTED_LANGUAGE_KEY);
const siteLangID = userLanguage ? Object.values(userLanguage)[0] : 1;

http.defaults.baseURL = process.env.REACT_APP_BASE_API_URL || 'https://imonesapi.tandemumstage.site';
http.defaults.baseURL += `/api/${siteLangID}`;

http.interceptors.response.use((response) => {
    if (response.data) {
        response.data = camelcaseKeys(response.data, { deep: true });
    }
    return response;
});

export const parseAxiosError = (err: any): any => {
    if ('isAxiosError' in err) {
        const body = err as AxiosError<any>;
        if (body.response?.status === 429) {
            // Rate limiting by cloudflare
            return {
                statusCode: err.status,
                message: 'Too many requests, please wait and try again later',
                errorCode: 'internalServerError',
            };
        }
        return {
            statusCode: err.status,
            message: err.message,
            errorCode: 'internalServerError',
        };
    }
};

export default http;
