import { useTranslation } from 'react-i18next';
import { ResponsiveLine } from '@nivo/line';

import SouthIcon from '@mui/icons-material/South';
import { FinancialStatsItem } from '../../../../../types/api';

import classnames from 'classnames';

import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { IState } from '../../../../../store';

const Container = styled.div`
    width: 100%;
    height: 100%;
`;

const Header = styled.div`
    font-size: 14px;
    font-weight: 500;
    color: ${(props) => props.theme.colors.lighterBlack};
    line-height: 140%;
    letter-spacing: 0.005em;
    padding: 0 0 16px 8px;
`;

const ChartWrapper = styled.div`
    width: 100%;
    height: 272px;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            height: 332px;
        `}
`;

const TooltipWrapper = styled.div`
    ${(props) =>
        css`
            background-color: ${props.theme.colors.white};
            border-radius: 8px;
            padding: 8px;
            box-shadow: 0px 3px 0px ${props.theme.colors.outlineAndPlaceholder};
        `}
`;

const BottomWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 16px;
`;

const ShowMoreButton = styled.div`
    text-decoration: underline;
    color: ${(props) => props.theme.colors.darkYellow};
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    margin: 0 16px;
`;

const ShowMoreText = styled.div`
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 0.005em;
`;

const DataTextWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
    letter-spacing: 0.005em;
    margin: 0 16px;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            width: auto;
        `}
`;

const ArrowIcon = styled(SouthIcon)`
    transition: transform 0.2s ease-in-out;
    font-size: 14px !important;
    &.up {
        transform: rotate(-180deg);
    }
`;

const ChartBottom = styled.div`
    ${(props) =>
        css`
            color: ${props.theme.colors.secondaryBlack};
            margin: 0 6px 0 0;
            &:first-child {
                margin-right: 20px;
                width: 70px;
            }
        `}
`;

interface Props {
    data: FinancialStatsItem[];
    showMore: boolean;
    onShowMore: (type: string) => void;
}

export const Sodra = (props: Props) => {
    const { t } = useTranslation();
    const { screenViewPort } = useSelector((state: IState) => state.global);

    const formatData = (data: FinancialStatsItem[]) => {
        const formattedData = data.map((item) => {
            return {
                x: new Date(item.date).toLocaleDateString('lt-LT'),
                y: item.amount,
            };
        });
        return { id: 'Sodra', color: '#bfdbeb', data: formattedData };
    };

    const slicedData = props.showMore
        ? props.data.slice(props.data.length - 8, props.data.length)
        : props.data.slice(props.data.length - 3 < 0 ? 0 : props.data.length - 1, props.data.length);

    return (
        <Container>
            <Header>{t('sodra_debt')}</Header>
            <ChartWrapper>
                <ResponsiveLine
                    enableSlices="x"
                    data={[formatData(props.data)]}
                    sliceTooltip={({ slice }) => {
                        return (
                            <TooltipWrapper>
                                <div>{slice.points[0].data.xFormatted.toString().replace('00:00:00', '')}</div>
                                <div>{slice.points[0].data.yFormatted} Eur.</div>
                            </TooltipWrapper>
                        );
                    }}
                    margin={{ top: 10, right: 30, bottom: screenViewPort !== 'desktop' ? 70 : 20, left: 50 }}
                    axisTop={null}
                    axisRight={null}
                    pointSize={1}
                    pointBorderWidth={2}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    colors={(data) => data.color}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: screenViewPort !== 'desktop' ? 90 : 0,
                        legendOffset: 36,
                        legendPosition: 'middle',
                        format: (value) => {
                            const index = props.data.findIndex((item) => item.date === value);
                            return index % 6 === 0 || index === props.data.length - 1 ? value : '';
                        },
                    }}
                />
            </ChartWrapper>
            <BottomWrapper>
                <div>
                    {slicedData.reverse().map((item, index) => {
                        return (
                            <DataTextWrapper key={index}>
                                <ChartBottom>{new Date(item.date).toLocaleDateString('lt-LT')}</ChartBottom>
                                <ChartBottom>{t('social_security_debt')}</ChartBottom>
                                <ChartBottom>{item.amount} Eur</ChartBottom>
                            </DataTextWrapper>
                        );
                    })}
                </div>
                <ShowMoreButton onClick={() => props.onShowMore('sodraDebt')}>
                    <ShowMoreText>{!props.showMore ? t('more') : t('less')}</ShowMoreText>
                    <ArrowIcon
                        className={classnames({
                            up: props.showMore,
                        })}
                    />
                </ShowMoreButton>
            </BottomWrapper>
        </Container>
    );
};
