import { useTranslation } from 'react-i18next';

import { ResponsiveLine } from '@nivo/line';
import SouthIcon from '@mui/icons-material/South';

import { IWorkerStatistics, WorkerStatisticItem } from '../../../../../types/api';

import classnames from 'classnames';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { IState } from '../../../../../store';

const Container = styled.div`
    width: 100%;
    height: 100%;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 16px 8px;
    line-height: 140%;
    color: ${(props) => props.theme.colors.lighterBlack};
    letter-spacing: 0.005em;
`;

const Header = styled.div`
    font-weight: 500;
    font-size: 14px;
`;

const Text = styled.div`
    font-size: 12px;
    text-transform: lowercase;
`;

const ChartWrapper = styled.div`
    width: 100%;
    height: 272px;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            height: 332px;
        `}
`;

const TooltipWrapper = styled.div`
    ${(props) =>
        css`
            background-color: ${props.theme.colors.white};
            border-radius: 8px;
            padding: 8px;
            box-shadow: 0px 3px 0px ${(props) => props.theme.colors.outlineAndPlaceholder};
        `}
`;

const BottomWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-top: 16px;
`;

const ShowMoreButton = styled.div`
    text-decoration: underline;
    color: ${(props) => props.theme.colors.darkYellow};
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    margin: 0 16px;
`;

const ShowMoreText = styled.div`
    font-size: 12px;
    font-weight: 500;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 0.005em;
`;

const DataTextWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
    letter-spacing: 0.005em;
    margin: 0 16px;
    ${(props) =>
        !props.theme.screen.desktop &&
        css`
            width: auto;
        `}
`;

const EmployeeAmountText = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 150px;
    width: 100%;
`;

const ArrowIcon = styled(SouthIcon)`
    transition: transform 0.2s ease-in-out;
    font-size: 14px !important;
    &.up {
        transform: rotate(-180deg);
    }
`;

const ChartDate = styled.span`
    ${(props) => css`
        color: #737373;
        font-family: 'Albert-Regular';
        font-size: 11px;
        font-weight: 400;
        margin-left: 6px;
        ${!props.theme.screen.desktop &&
        css`
            font-size: 8px;
        `}
    `}
`;

const ChartBottom = styled.div`
    ${(props) =>
        css`
            color: ${props.theme.colors.secondaryBlack};
            margin: 0 6px 0 0;
            &:first-child {
                margin-right: 20px;
                width: 70px;
            }
        `}
`;

interface Props {
    employeeStatistics: IWorkerStatistics;
    showMore: boolean;
    onShowMore: (type: string) => void;
}

export const EmployeeAmount = (props: Props) => {
    const { t } = useTranslation();
    const { screenViewPort } = useSelector((state: IState) => state.global);

    const formatData = (data: WorkerStatisticItem[]) => {
        const formattedData = data.map((item) => {
            return {
                x: new Date(item.date).toLocaleDateString('lt-LT'),
                y: item.count,
            };
        });
        return { id: 'Darbuotojai', color: '#bfdbeb', data: formattedData };
    };

    const slicedData = () =>
        props.showMore
            ? props.employeeStatistics.totalCount
                  .slice(props.employeeStatistics.totalCount.length - 8, props.employeeStatistics.totalCount.length)
                  .reverse()
            : props.employeeStatistics.totalCount
                  .slice(props.employeeStatistics.totalCount.length - 1, props.employeeStatistics.totalCount.length)
                  .reverse();

    const latestWorkerStatstDate = new Date(
        props.employeeStatistics.totalCount[props.employeeStatistics.totalCount.length - 1]?.date
    ).toLocaleDateString('lt-LT');

    return (
        <Container>
            <HeaderWrapper>
                <Header>{t('employee_amount')}</Header>
                <Text>
                    {slicedData()[0].count} {t('employees')} ({t('insured')})
                    <ChartDate>{`(${latestWorkerStatstDate})`}</ChartDate>
                </Text>
            </HeaderWrapper>
            <ChartWrapper>
                <ResponsiveLine
                    enableSlices="x"
                    data={[formatData(props.employeeStatistics.totalCount)]}
                    sliceTooltip={({ slice }) => {
                        return (
                            <TooltipWrapper>
                                <div>{slice.points[0].data.xFormatted.toString().replace('00:00:00', '')}</div>
                                <div>{slice.points[0].data.yFormatted}</div>
                            </TooltipWrapper>
                        );
                    }}
                    margin={{ top: 10, right: 30, bottom: screenViewPort !== 'desktop' ? 70 : 20, left: 50 }}
                    axisTop={null}
                    axisRight={null}
                    pointSize={1}
                    pointBorderWidth={2}
                    pointLabelYOffset={-12}
                    colors={(data) => data.color}
                    yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickValues: 7,
                    }}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: screenViewPort !== 'desktop' ? 90 : 0,
                        legendOffset: 36,
                        legendPosition: 'middle',
                        format: (value) => {
                            const index = props.employeeStatistics.totalCount.findIndex((item) => item.date === value);
                            return index % 5 === 0 || index === props.employeeStatistics.totalCount.length - 1
                                ? value
                                : '';
                        },
                    }}
                />
            </ChartWrapper>
            <BottomWrapper>
                <div>
                    {slicedData().map((item, index) => {
                        return (
                            <DataTextWrapper key={index}>
                                <ChartBottom>{new Date(item.date).toLocaleDateString('lt-LT')}</ChartBottom>
                                <ChartBottom>{t('employee_amount')}</ChartBottom>
                                <ChartBottom>{item.count}</ChartBottom>
                            </DataTextWrapper>
                        );
                    })}
                </div>
                <ShowMoreButton onClick={() => props.onShowMore('employeeAmount')}>
                    <ShowMoreText>{!props.showMore ? t('more') : t('less')}</ShowMoreText>
                    <ArrowIcon
                        className={classnames({
                            up: props.showMore,
                        })}
                    />
                </ShowMoreButton>
            </BottomWrapper>
        </Container>
    );
};
