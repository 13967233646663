import { CategoriesResponse, TopicsResponse } from '../types/api';
import http from './client/http';

export const getCategoriesList = () =>
    http.get<CategoriesResponse>(`/categories?withTopics=true&withHeads=true`).then((res) => res.data.data);

export const getCategoryTopics = (categoryId: number, withHeads = false) => {
    let additionalParams = '';
    if (withHeads) {
        additionalParams = '?withHeads=true';
    }
    return http.get<TopicsResponse>(`/categories/${categoryId}/topics${additionalParams}`).then((res) => res.data.data);
};
