import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { ArticlesAndSidebar } from '../../components/Articles/ArticlesAndSidebar/ArticlesAndSidebar';
import { SearchAndFilter } from '../../components/Articles/SearchAndFilter/SearchAndFilter';
import { getArticlesList } from '../../services/articles.service';
import { setLoading } from '../../store/global.slice';
import { ArticlesPreview } from '../../types/api';

const ArticlesContainer = styled.div`
    ${(props) => css`
        display: flex;
        justify-content: center;
        background-color: ${props.theme.colors.greyBg};
    `}
`;
const Wrap = styled.div`
    width: 1254px;
    margin: 0 16px;
`;

const SearchAndFilterContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 33px;
`;

export const ArticlesPage = () => {
    const [articles, setArticles] = useState<ArticlesPreview[]>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading(true));
        getArticlesList().then((resp) => {
            const articlesWithContent = resp.data.filter((article) => article.contentTeaser);
            setArticles(articlesWithContent);
            dispatch(setLoading(false));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SearchAndFilterContainer>
                <Wrap>
                    <SearchAndFilter setArticles={setArticles} />
                </Wrap>
            </SearchAndFilterContainer>
            <ArticlesContainer>
                <Wrap>
                    <ArticlesAndSidebar articleData={articles} adAmount={5} setArticles={setArticles} />
                </Wrap>
            </ArticlesContainer>
        </>
    );
};
