import styled, { css } from 'styled-components';
import { TextField } from '@mui/material';

const InputField = styled(TextField)`
    ${(props) => css`
        width: 100%;
        .input-styles {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            background-color: ${props.theme.colors.white};
            border: 1px solid ${props.theme.colors.outlineGrey};
            max-height: 48px;
            padding-top: 22px;
            padding-right: 12px;
            padding-bottom: 4px;
            padding-left: 12px;
            cursor: pointer;
            &:focus-within,
            &:hover {
                border: 1px solid ${props.theme.colors.outlineAndPlaceholder};
            }
        }
        #article_search {
            background-color: ${props.theme.colors.greyBg};
            border-radius: 8px 0 0 8px;
            border: none;
            height: 22px;
        }
        .label-styles {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: ${props.theme.colors.outlineAndPlaceholder} !important;
        }
        &.main-search {
            .input-styles {
                height: 20px;
                background-color: ${props.theme.colors.greyBg};
                border: 1px solid ${props.theme.colors.greyBg};
                padding-left: 12px;
                border-radius: 0;
                &:focus-within,
                &:hover {
                    background-color: ${props.theme.colors.greyBg} !important;
                    border: 1px solid ${props.theme.colors.outlineAndPlaceholder};
                }
                ${props.theme.screen.mobile &&
                css`
                    border-radius: 0 8px 8px 0;
                    padding-left: 31px;
                `}
            }
            .label-styles {
                ${props.theme.screen.mobile &&
                css`
                    padding-left: 24px;
                `}
            }
        }
    `}
`;

interface Props {
    id: string;
    placeholder: string;
    class?: string;
    onChange: (value: any) => void;
    value?: any;
    type?: 'text' | 'number';
}

export const InputItem = (props: Props) => {
    return (
        <>
            <InputField
                type={props.type || 'text'}
                autoComplete="off"
                id={props.id}
                label={props.placeholder}
                className={props.class}
                value={props.value}
                variant="filled"
                onChange={(e) => props.onChange(e.target.value)}
                inputProps={{ className: 'input-styles' }}
                InputLabelProps={{ className: 'label-styles' }}
                size="small"
                sx={{
                    '& :after': { display: 'none' },
                    '& :before': { display: 'none' },
                    '& .MuiInputBase-root': { borderRadius: '8px' },
                }}
            />
        </>
    );
};
