import L from 'leaflet';
import { TileLayer, Marker, Popup, MapContainer, useMap } from 'react-leaflet';
import icon from '../../assets/images/Leaflet/yellow_pin.svg';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { IState } from '../../store';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import 'leaflet/dist/leaflet.css';

const Map = styled.div`
    position: sticky;
    top: 91px;
    width: 100%;
    &.gate-active {
        top: 221px;
    }
`;
const FullMap = styled(MapContainer)`
    width: 100%;
    height: 91vh;
    &.height {
        height: 313px;
    }
`;

const DefaultIcon = L.icon({
    iconRetinaUrl: icon,
    iconUrl: icon,
});

L.Marker.prototype.options.icon = DefaultIcon;

type Cord = {
    lat: number;
    lng: number;
};

const LeafletMap = (props: any) => {
    const { gateIsActive } = useSelector((state: IState) => state.global);
    const [position, setPosition] = useState<Cord>({ lat: 54.6872, lng: 25.2797 }); // set default position

    useEffect(() => {
        setPosition((prev) => ({
            ...prev,
            lat: props.coordinates?.latitude || 54.6872,
            lng: props.coordinates?.longitude || 25.2797,
        }));
    }, [props.coordinates?.latitude, props.coordinates?.longitude]);

    const Recenter = ({ lat, lng }: { lat: number; lng: number }) => {
        const map = useMap();
        useEffect(() => {
            map.setView([lat, lng]);
        }, [lat, lng, map]);
        return null;
    };

    const isCoordinatesSet = () => {
        if (!props.coordinates?.latitude && !props.coordinates?.longitude) {
            return <Popup>Įmonės vieta nenurodyta</Popup>;
        } else {
            return (
                <Popup>
                    {`${props.coordinates?.street} 
                ${props.coordinates?.buildingNum}
                ${props.coordinates?.city}
                `}
                </Popup>
            );
        }
    };

    return (
        <Map
            className={classNames({
                'gate-active': gateIsActive,
            })}
        >
            <FullMap center={[0, 0]} zoom={13} scrollWheelZoom={true} className={classNames(props.leafletHeight)}>
                <Recenter lat={position.lat || 54.6872} lng={position.lng || 25.2797} />
                <TileLayer
                    attribution='<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://api.mapbox.com/styles/v1/rokelis3/clb1yxoyc000814pnsfpegajg/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoicm9rZWxpczMiLCJhIjoiY2xiMXozM2JnMWdndzNwb3FsbzF6NHhhMSJ9.wr_zI3BDTQmEJMX-gVecfg"
                />
                <Marker position={[position.lat || 54.6872, position.lng || 25.2797]}>{isCoordinatesSet()}</Marker>
            </FullMap>
        </Map>
    );
};

export default LeafletMap;
