import { store } from '../store';
import { setLoading, setPagination } from '../store/global.slice';
import { ArticleResponse, ArticlesResponse } from '../types/api';
import http from './client/http';

export const getArticlesList = (page = 1, filter?: string) => {
    return http.get<ArticlesResponse>(`/articles?page=${page}${filter ? filter : ''}`).then((res) => {
        store.dispatch(setPagination(res.data));
        const articlePagination = {
            key: 'articles',
            pagination: {
                currentPage: res.data.data.currentPage,
                perPage: res.data.data.perPage,
                total: res.data.data.total || 0,
                firstPageUrl: res.data.data.firstPageUrl,
                query: queryParamSplitter(res.data.data.firstPageUrl),
            },
        };
        store.dispatch(setPagination(articlePagination));
        return res.data.data;
    });
};
export const getArticle = (id: number) => {
    store.dispatch(setLoading(true));
    return http.get<ArticleResponse>(`/articles/${id}`).then((res) => {
        store.dispatch(setLoading(false));
        return res.data.data;
    });
};

const queryParamSplitter = (query: string) => {
    const splitParams = query.split('&');
    const queryParams = splitParams.slice(1);
    const queryObj: any = {};
    queryParams.forEach((param) => {
        const [key, value] = param.split('=');
        queryObj[key] = decodeURIComponent(value);
    });

    return queryObj;
};
