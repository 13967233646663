import { Topic } from '../../types/api';

export interface ICategoriesState {
    categoriesList: ICategory[];
    topics: {
        [key: number]: Topic[];
    };
}

export interface ICategory {
    id: number;
    name: string;
    topics: Topic[];
}

export const initialCategoriesState: ICategoriesState = {
    categoriesList: [],
    topics: {},
};
