import styled, { css } from 'styled-components';
// We dont know it the logo is goingt to come or just a boolean, so I left this, because there is a possibility this will be needed.
// import certificate from '../../../../assets/images/BusinessListCard/certificate.svg';
import { Stars } from '../../../Stars/Stars';
import { CompanyStatus } from './CompanyStatus';
import { useSelector } from 'react-redux';
import { IState } from '../../../../store';
import { useTranslation } from 'react-i18next';
import { CompanyData, SocialNetwork } from '../../../../types/api';
import React from 'react';

import { ReactComponent as Facebook } from '../../../../assets/images/InnerCompanyIcons/facebook_icon.svg';
import { ReactComponent as Linkedin } from '../../../../assets/images/InnerCompanyIcons/linkedIn_icon.svg';
import { ReactComponent as Google } from '../../../../assets/images/InnerCompanyIcons/social_icon.svg';
import { ReactComponent as Twitter } from '../../../../assets/images/InnerCompanyIcons/twitter_icon.svg';
import { ReactComponent as Instagram } from '../../../../assets/images/InnerCompanyIcons/instagram_icon.svg';
import editOutlinedIcon from '@mui/icons-material/EditOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import lext from '../../../../assets/images/BusinessListCard/lext.png';
import { NumberFormater } from '../../../NumberFormater/NumberFormater';

const HeadContainer = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
`;
const HeaderRow = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        ${props.theme.screen.mobile &&
        css`
            align-items: flex-start;
            position: relative;
        `}
    `}
`;
const Icons = styled.div`
    display: flex;
    align-items: center;
`;
const LogoHeader = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            align-items: flex-start;
        `}
    `}
`;
const Header = styled.div`
    ${(props) => css`
        font-size: 32px;
        font-weight: 600;
        line-height: 38px;
        ${props.theme.screen.mobile &&
        css`
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
            margin-top: 16px;
        `}
    `}
`;
const EditOutlinedIcon = styled(editOutlinedIcon)`
    ${(props) => css`
        color: ${props.theme.colors.darkGrey};
    `}
`;
const LextIcon = styled.img`
    ${(props) => css`
        width: 42px;
        margin-right: 40px;
        ${props.theme.screen.mobile &&
        css`
            position: absolute;
            bottom: -34px;
        `}
    `}
`;

const Logo = styled.img`
    ${(props) => css`
        object-fit: contain;
        height: 48px;
        max-width: 173px;
        margin-right: 24px;
        ${props.theme.screen.mobile &&
        css`
            height: 36px;
            max-width: 130px;
        `}
    `}
`;
const WriteReview = styled.div`
    ${(props) => css`
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        color: ${props.theme.colors.darkYellow};
        text-decoration: underline;
        text-transform: uppercase;
        border: none;
        background-color: transparent;
        padding: 0;
        letter-spacing: 0.05em;
        text-underline-offset: 3px;
        margin-left: 16px;
        cursor: pointer;
        &:hover {
            font-weight: 700;
        }
        ${props.theme.screen.mobile &&
        css`
            margin: 20px 0 0 0;
        `}
    `}
`;
const StarsContainer = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        margin-top: 16px;
        justify-content: space-between;
        flex-wrap: wrap;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            align-items: flex-start;
            height: auto;
        `}
    `}
`;

const StarsWrap = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        ${props.theme.screen.mobile &&
        css`
            flex-direction: column;
            align-items: flex-start;
            margin-top: 20px;
            order: 3;
        `}
    `}
`;
const CompanyStat = styled(CompanyStatus)`
    ${(props) => css`
        ${props.theme.screen.mobile &&
        css`
            order: 1;
        `}
    `}
`;
const ContactsContainer = styled.div`
    ${(props) => css`
        display: flex;
        border: solid 1px ${props.theme.colors.outlineGrey};
        padding: 16px;
        margin: 24px 0;
        ${props.theme.screen.mobile &&
        css`
            border-left: none;
            border-right: none;
            width: 100%;
            transform: translateX(-16px);
            order: 3;
            margin: 33px 0 0 0;
        `}
    `}
`;
const Wrap = styled.div`
    ${(props) => css`
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        height: 25px;
        ${props.theme.screen.mobile &&
        css`
            margin: 0;
        `}
    `}
`;
const Name = styled.div`
    margin-left: 8px;
`;
const Info = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    overflow-wrap: anywhere;
`;
const Contacts = styled.div`
    ${(props) => css`
        width: 100%;
        margin: 8px;
        ${props.theme.screen.mobile &&
        css`
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid ${props.theme.colors.outlineGrey};
            border-radius: 4px;
            height: 36px;
            width: 36px;
            margin: 0;
        `}
    `}
`;
const ContactsWrap = styled.div`
    ${(props) => css`
        display: flex;
        width: 100%;
        ${props.theme.screen.mobile &&
        css`
            justify-content: space-between;
        `}
    `}
`;
// const CertificateContainer = styled.div`
//     display: flex;
//     align-items: center;
// `;
const Divider = styled.div`
    ${(props) => css`
        height: 100%;
        width: 1px;
        background-color: ${props.theme.colors.outlineGrey};
        margin: 0 16px;
    `}
`;
// const Certificate = styled.img`
//     ${(props) => css`
//         width: 56px;
//         ${props.theme.screen.mobile &&
//         css`
//             width: 36px;
//         `}
//     `}
// `;
const Anchor = styled.a`
    ${(props) => css`
        display: flex;
        align-items: center;
        text-decoration: none;
        height: 100%;
        color: ${props.theme.colors.black};
        cursor: pointer;
    `}
`;

const SocialIconsWrap = styled.div`
    ${(props) => css`
        display: flex;
        gap: 24px;
        ${props.theme.screen.mobile &&
        css`
            order: 1;
        `}
    `}
`;
const SocialIcon = styled.a`
    .social-icon {
        height: 24px !important;
        width: 24px !important;
    }
`;

interface Props {
    data: CompanyData;
}

export const Head = (props: Props) => {
    const { t } = useTranslation();
    const editPage =
        `https://klientams.1588.lt/web/login?redirect=/setcompany/${props.data.slug}` ||
        `${process.env.REACT_APP_EDIT_REDIRECT}/${props.data.slug}`;

    const { screenViewPort } = useSelector((state: IState) => state.global);

    const webLinkFormater = (website: string) => {
        if (!website) {
            return '';
        }
        const array = website.split('/');
        return array[2];
    };

    const stopPropogation = (e: any) => {
        e.stopPropagation();
    };

    const showInMapClicked = (lat: number, long: number) => {
        if (lat && long) {
            window.open('https://maps.google.com?q=' + lat + ',' + long);
        }
    };

    const socialIconsFilter = () => {
        const socials: { [key: string]: JSX.Element } = {
            facebook: <Facebook className="social-icon" />,
            linkedin: <Linkedin className="social-icon" />,
            google: <Google className="social-icon" />,
            twitter: <Twitter className="social-icon" />,
            instagram: <Instagram className="social-icon" />,
        };
        const filterIcons = props.data.socialNetworks.map((network: SocialNetwork, id) => {
            const socialName = network.socialNetwork;
            return (
                <SocialIcon href={network.url} target="_blank" key={id}>
                    {socials[socialName]}
                </SocialIcon>
            );
        });
        return filterIcons;
    };

    const information = [
        {
            name: t('address'),
            information: (
                <Anchor
                    onClick={() => {
                        showInMapClicked(props.data.address.latitude, props.data.address.longitude);
                    }}
                >
                    {`${props.data.address.street}${props.data.address.street ? ',' : ''} ${
                        props.data.address.buildingNum
                    }${props.data.address.roomNum ? '-' : ''}${props.data.address.roomNum}${
                        props.data.address.buildingNum.trim() ? ',' : ''
                    } ${props.data.address.city} ${props.data.address.zip}${props.data.address.zip ? ',' : ''} ${
                        props.data.address.state
                    }${props.data.address.state ? ',' : ''} ${props.data.address.country}`}
                </Anchor>
            ),
            icons: (
                <Anchor
                    onClick={() => {
                        showInMapClicked(props.data.address.latitude, props.data.address.longitude);
                    }}
                >
                    <RoomOutlinedIcon fontSize="small" />
                </Anchor>
            ),
        },
        {
            name: t('phone'),
            information: (
                <Anchor onClick={(e) => stopPropogation(e)} href={`tel:${props.data.phone}`}>
                    <NumberFormater number={props.data.phone} />
                </Anchor>
            ),
            icons: (
                <Anchor onClick={(e) => stopPropogation(e)} href={`tel:${props.data.phone}`}>
                    <PhoneOutlinedIcon fontSize="small" />
                </Anchor>
            ),
        },
        {
            name: t('email'),
            information: (
                <Anchor onClick={(e) => stopPropogation(e)} href={`mailto:${props.data.email}`}>
                    {props.data.email}
                </Anchor>
            ),
            icons: (
                <Anchor onClick={(e) => stopPropogation(e)} href={`mailto:${props.data.email}`}>
                    <MailOutlinedIcon fontSize="small" />
                </Anchor>
            ),
        },
        {
            name: t('website'),
            information: (
                <Anchor onClick={(e) => stopPropogation(e)} href={props.data.website} target="_blank">
                    {webLinkFormater(props.data.website)}
                </Anchor>
            ),
            icons: (
                <Anchor onClick={(e) => stopPropogation(e)} href={props.data.website} target="_blank">
                    <LanguageOutlinedIcon fontSize="small" />
                </Anchor>
            ),
        },
        {
            name: t('manager'),
            information: props.data.manager?.name,
            icons: <PermIdentityOutlinedIcon fontSize="small" />,
        },
    ];

    const goToreviews = () => {
        window.open(`${process.env.REACT_APP_IMONES_URL}/imones-kortele/${props.data.id}`, '_blank');
    };

    return (
        <HeadContainer>
            <HeaderRow>
                <LogoHeader>
                    {props.data.logo !== null && <Logo src={props.data.logo.logoPath} />}
                    <Header>{props.data.name}</Header>
                </LogoHeader>
                <Icons>
                    {props.data.lextIntegration && <LextIcon src={lext} />}
                    <Anchor href={editPage} target="_blank">
                        <EditOutlinedIcon />
                    </Anchor>
                </Icons>
            </HeaderRow>
            <StarsContainer>
                <StarsWrap>
                    <Stars
                        rating={Number(props.data.rating)}
                        feedbackCount={props.data.feedbackCount}
                        starClass={'bigger-stars'}
                    />
                    <WriteReview onClick={() => goToreviews()}>{t('write_a_review')}</WriteReview>
                </StarsWrap>
                {socialIconsFilter().length > 0 && <SocialIconsWrap>{socialIconsFilter()}</SocialIconsWrap>}
                {/* At the momment the status will show up only when the legal status is not an empty string. Odoo only send '' atm. */}
                {props.data.legalStatus && <CompanyStat status={props.data.legalStatus} />}
            </StarsContainer>
            <ContactsContainer>
                <ContactsWrap>
                    <>
                        {information.map((data, index, row) => {
                            return (
                                <React.Fragment key={index}>
                                    <Contacts>
                                        <Wrap>
                                            {data.icons}
                                            {screenViewPort !== 'mobile' && <Name>{data.name}</Name>}
                                        </Wrap>
                                        {screenViewPort !== 'mobile' && <Info>{data.information}</Info>}
                                    </Contacts>
                                    {screenViewPort !== 'mobile' && index + 1 !== row.length && <Divider />}
                                </React.Fragment>
                            );
                        })}
                        {/* No data given atm */}
                        {/* {props.data.certificates && (
                            <CertificateContainer>
                                <Certificate src={certificate} />
                            </CertificateContainer>
                        )} */}
                    </>
                </ContactsWrap>
            </ContactsContainer>
        </HeadContainer>
    );
};
